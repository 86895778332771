import React, { useState } from 'react'
import { FaChevronCircleLeft } from 'react-icons/fa';

const SidebarSubMenu = (props) => {
    const [Chevron, setChevron] = useState("");

    const handleChevronRotation = () => {
        setChevron((preVal) => {
            return (preVal === "") ? "active" : "";
        });
    }
    const handleCollapse = (id) => {
        const ul = document.querySelector(`#${id}`);
        (ul.style.height === props.children.length * 35 + "px") ? (ul.style.height = 0) : (ul.style.height = props.children.length * 35 + "px");
    }
    return (
        <div className="SidebarSubMenu">
            <li onClick={() => { handleChevronRotation(); handleCollapse(props.id); }}>
                <div className="IconName">
                    <div className="icon">
                        {props.icon}
                    </div>
                    <div className="name">
                        {props.title}
                    </div>
                </div>
                <div className="BadgeChavron">
                    <FaChevronCircleLeft className={`chevron ${Chevron}`} />
                </div>
            </li>
            <ul id={props.id} style={{ height: 0 }}>
                {
                    (Array.isArray(props.children)) ? (
                        props.children.map((val) => {
                            return val;
                        })
                    ) : (
                        props.children
                    )
                }
            </ul>
        </div>
    )
}

export default SidebarSubMenu
