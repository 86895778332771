import React from 'react'

const SidebarMenu = (props) => {
    return (
        <ul className="SidebarMenu">
            {
                (Array.isArray(props.children))?(
                    props.children.map((val)=>{
                        return val;
                    })
                ):(
                    props.children
                )
            }
        </ul>
    )
}

export default SidebarMenu
