import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";

// import EditMod from "./EditMod";
// import AddMod from "./AddMod";
import Modal from "@material-ui/core/Modal";

import { useHistory } from "react-router-dom";

function DisputeTransection() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataValues, setDataValues] = useState([]);
  const history = useHistory();
 
  
  



 
  const GetData = async () => {
    try {
      setIsLoading(true)
      const res = await API().getDisputeRequestList();
      console.log(res,'resssss')
      let newData = [];
      res.transection.map((item, index) => {
       
// console.log(item,'itemmm')
        newData.push({
          s_no: index + 1,
        
        aprove:( <>
            {item.tansection_id>0?
        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-danger " onClick={()=>aprovedTransection(item.tansection_id)} >Approve</button>
        : <button style={{ borderRadius: "20px" ,border:'1px solid black'}} className="btn px-4  "  >Buyer has not made transaction yet</button>}
        </>),
        details:(  <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-info  " onClick={() =>TrasectionDetails(item) } > Detail</button>
       
        ),
        
          ...item,
        });

      });
      setIsLoading(false);

      setDataValues(newData);
    } catch (err) {
      console.log(err);
    }
  };
 
  const aprovedTransection = async (tansection_id) => {
    try {
        const res = await API().postAcceptTransection(
            tansection_id
        )

        console.log(tansection_id, 'tansection_id')
        console.log(res, 'res tansection_id')
        if (res.status) {
            history.replace(
                "/transection/aproved",

            )

        }
    } catch (err) { console.log(err, 'errAcceptTransection') }
}
  const TrasectionDetails = (item) => {
        
    history.push(
        "/transection/details",
          item 
    )
}
 

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "s_no",
      label: "S.no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "from_user",
      label: "Seller",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "to_user",
      label: "Buyer",
      options: {
        filter: true,
        sort: true,
      },
    },
   
    {
      name: "aprove",
      label: "Approve",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "details",
      label: "Details",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        // material ui v4 only
        size: "small",
      };
    },
  
  };
  useEffect(() => {
    GetData();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center container  align-items-center">
        <div className="spinner-border text-secondary" role="status"></div>
      </div>
    );
  } else {
    return (
      <div className=" container ">
        <MUIDataTable
          title={"Dispute Transaction"}
          data={dataValues}
          columns={columns}
          options={options}
        />
       

    

     

      </div>
    );
  }
}

export default DisputeTransection;

 

