
import React, { useEffect, useState } from "react";
import API from "../../Apis/Api";
const AdDetails = (props) => {
    
    const { ad_id, first_name, last_name, email, price, symbol, name, minimum_crypto_amount, maximum_crypto_amount, bank_account_number, bank_name, country_name, currency_symbol } = props.location.state
    const [isLoading, setIsLoading] = useState(true);
    const [apiValues, setApiValues] = useState([]);

    const GetData = async () => {
        try {
            setIsLoading(true)
            const res = await API().getBidRequest(ad_id);

            setApiValues(res.request);

            setIsLoading(false);

        } catch (err) {
            console.log(err, 'getBidRequest');
        }
    };

    useEffect(() => {
        GetData();
    }, []);
    return (
        <div className="container">
            <div className=" col-xl-5 col-lg-6 mx-auto border shadow rounded d-flex flex-wrap justify-content-center">
                <div className=" col-11 m-3 ">

                    <div className="my-3 text-center  ">
                        <h3 className="mt-2 ">  Ad Details</h3>
                    </div>


                    <div className="col-9 mt-2   mx-auto   d-flex">
                        <h6 style={{ marginTop: 10 }}>user:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {first_name} {last_name}
                        </h6>
                    </div>
                    <div className="col-9 mx-auto  d-flex">
                        <h6 style={{ marginTop: 10 }}>Email:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{email}</h6>
                    </div>

                    <div className="col-9  mx-auto d-flex">
                        <h6 style={{ marginTop: 10 }}>Price:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{price} {currency_symbol}/{symbol}</h6>
                    </div>
                    <div className="col-9  mx-auto  d-flex">

                        <h6 style={{ marginTop: 10 }}>Amount Limit:</h6>
                        <div className="d-flex">
                            <h6 style={{ color: "green", marginTop: 10, marginLeft: "10px" }}>
                                {minimum_crypto_amount}{symbol}
                            </h6>
                            <h6 style={{ marginTop: 10, }}>
                                -
                            </h6>
                            <h6 style={{ color: "red", marginTop: 10 }}>
                                {maximum_crypto_amount}{symbol}

                            </h6>
                        </div>
                    </div>


                    <div className="col-9  mx-auto   d-flex">
                        <h6 style={{ marginTop: 10 }}>Payment Method:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {name}
                        </h6>
                    </div>


                    <div className="col-9  mx-auto d-flex">
                        <h6 style={{ marginTop: 10 }}>Country:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{country_name}</h6>
                    </div>
                    {bank_account_number !== null ? (

                        <div className="col-9  mx-auto d-flex">
                            <h6 style={{ marginTop: 10 }}>Bank Account Number:</h6>
                            <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{bank_account_number}</h6>
                        </div>
                    ) : (<></>)}
                    <div className="col-9  mx-auto d-flex">
                        <h6 style={{ marginTop: 10 }}> Bank Name:</h6>
                        <h6 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{bank_name}</h6>
                    </div>

                </div>
            </div>
            {isLoading ? (
                <div className="d-flex  justify-content-center mt-5">
                    <div className="spinner-border text-secondary  mt-5" role="status"></div>
                </div>
            ) :

                (
                    <div className="col-10 mx-auto">
                        <div  className=" mx-auto mt-5 pb-4 border shadow rounded row justify-content-center">

                            <div className="mt-3  ">
                                <h3 className="mt-2 mx-2">  Request Details</h3>
                            </div>
                            {apiValues.map((item) => {

                                // console.log(item, 'iiiii')
                                return (

                                    <div style={{backgroundColor:"rgba(48, 73, 99,0.3)"}} className=" col-xl-5  col-lg-7  col-sm-11 mx-1  mt-4  border  rounded d-flex flex-wrap justify-content-center">
                                        <div  className=" col-11 m-2 ">
                                            <div className="col-9 mt-2   mx-auto   d-flex">
                                                <h6 style={{ marginTop: 10 }}>user:</h6>
                                                <h6 style={{ color: "white", marginTop: 10, marginLeft: "10px" }}>
                                                    {item.first_name} {item.last_name}
                                                </h6>
                                            </div>
                                            <div className="col-9 mx-auto  d-flex">
                                                <h6 style={{ marginTop: 10 }}>Email:</h6>
                                                <h6 style={{ color: "white", marginTop: 10, marginLeft: "10px" }}>{item.email}</h6>
                                            </div>

                                            <div className="col-9  mx-auto d-flex">
                                                <h6 style={{ marginTop: 10 }}>Quantity:</h6>
                                                <h6 style={{ color: "white", marginTop: 10, marginLeft: "10px" }}>{item.quantity} {symbol} </h6>
                                            </div>
                                            <div className="col-9  mx-auto  d-flex">

                                                <h6 style={{ marginTop: 10 }}>Amount Offer:</h6>
                                                <h6 style={{ color: "white", marginTop: 10, marginLeft: "10px" }}>{item.bid_amount} {item.user_currency} </h6>
                                            </div>


                                            


                                            <div className="col-9  mx-auto d-flex">
                                                <h6 style={{ marginTop: 10 }}>Country:</h6>
                                                <h6 style={{ color: "white", marginTop: 10, marginLeft: "10px" }}>{item.country}</h6>
                                            </div>


                                        </div>
                                    </div>
                                )
                            })}




                        </div>
                    </div>
                )}
        </div>
    )
}

export default AdDetails
