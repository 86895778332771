import React from 'react'

const SidebarContent = (props) => {
    return (
        <div className="sidebarcontent">
            {
                (Array.isArray(props.children))?(
                    props.children.map((val)=>{
                        return val;
                    })
                ):(
                    props.children
                )
            }
        </div>
    )
}

export default SidebarContent
