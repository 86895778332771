import React, { useEffect, useState, useContext } from "react";
import { UserContext } from "./Context";
import { useHistory } from "react-router-dom";

import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";



import { BsEyeSlash, BsEye } from "react-icons/bs";
import { LoginAPI } from "../Apis/Api";

function Login() {

    const { new_Data, setNew_Data } = useContext(UserContext);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [validation, setValidation] = useState("");


    const [isLoading, setIsLoading] = useState(false);
    const [visiblepass, Setvisiblepass] = useState({
        Type: "password",
        iconName: BsEyeSlash,
    });


    const history = useHistory();


    const userLogin = async () => {
        setIsLoading(true);

        
        if (email.length <= 0 || password.length <= 0) {
            setValidation("email or password invalid");
            setIsLoading(false);
        } else {
                try {
                //   const userData = { userEmail: email, userPassord: password };
                const responce = await LoginAPI().signin(email, password);


                // console.log(responce.data, "data")
                if (responce.data.type === "admin") {
                    let data = JSON.stringify(responce);
                    
                    history.replace("/banks/list");
                    // console.log(data, "admindata")
                    localStorage.setItem("userData", data);
                    setNew_Data(responce.data)

                } else {
                    setIsLoading(false);
                    // console.log( "admindata elsesss")
                    setValidation("email or password invalid");
                }
            } catch (err) {
                console.log(err, 'errlogin')
            }
            }
       
    };
    if (isLoading) {
        return (
            <div
                style={{ width: "100vw", height: "100vh" }}
                className="d-flex justify-content-center border align-items-center"
            >
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    } else {
        return (
            <div className="container ">
                <div style={{}} className="col-12 col-lg-4 col-lg-4 mx-auto mt-5">
                    <div className="d-flex justify-content-center ">
                        <h1 style={{ fontSize: "3.5rem", color: "#A38195" }}>
                            Folo
                        </h1>
                        <h1 style={{ marginTop: 10, color: "#502547" }}>Swap</h1>
                    </div>
                    <div className="shadow  mt-2 pt-3 pb-3">
                        <h4 style={{ color: "#9B618F" }} className="text-center">
                            Login to FoloSwap
                        </h4>
                        <div className="pt-3 pb-3">
                            <input
                                value={email}
                                className="form-control shadow-sm mx-auto"
                                style={{
                                    width: "90%",
                                    paddingLeft: 20,
                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    borderWidth: 1,
                                    borderRadius: 5,
                                    borderColor: "rgb(199, 199, 199)",
                                }}
                                placeholder="Email Address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            <div
                                className=" mt-3 d-flex justify-content-between shadow-sm mx-auto "
                                style={{
                                    width: "90%",
                                    height: "45px",

                                    paddingTop: 10,
                                    paddingBottom: 10,
                                    border: "1px solid rgb(199, 199, 199)",
                                    borderRadius: 5,
                                }}
                            >
                                <input
                                    value={password}
                                    className="form-control border-0 shadow-none  "
                                    style={{
                                        width: "85%",
                                        paddingLeft: 20,
                                    }}
                                    type={visiblepass.Type}
                                    placeholder="Password"
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <visiblepass.iconName
                                    size={30}
                                    style={{ marginRight: "5%" }}
                                    onClick={() => {
                                        visiblepass.Type === "password"
                                            ? Setvisiblepass({
                                                Type: "text",
                                                iconName: BsEye,
                                            })
                                            : Setvisiblepass({
                                                Type: "password",
                                                iconName: BsEyeSlash,
                                            });
                                    }}
                                />
                            </div>

                            <div className="text-center text-danger">{validation}</div>


                            <button
                                style={{ width: "90%", fontSize: 25, marginLeft: "5%",marginTop:'5px',backgroundColor:'#502547' }}
                                type="button"
                                className="btn btn-success  "
                                onClick={() => userLogin()}
                            >
                                Log In
                            </button>


                        </div>
                    </div>
                </div>



            </div>
        );
    }
}

export default Login;
