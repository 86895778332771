import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { IconButton, Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";


function DeclineTransections() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataValues, setDataValues] = useState([]);
  const history = useHistory();
  
  const GetData = async () => {
    try {
      setIsLoading(true)
      const res = await API().getDeclineTransectionList();
      console.log(res.transection,'getDeclineTransectionList')
      let newData = [];
      res.transection.map((item, index) => {
       
// console.log(item,'itemmm')
        newData.push({
          s_no: index + 1,
        
       
        details:( <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-info " onClick={()=>TrasectionDetails(item)} >Details</button>
        ),
        
          ...item,
        });

      });
      setIsLoading(false);

      setDataValues(newData);
    } catch (err) {
      console.log(err);
    }
  };
 

  const TrasectionDetails = (item) => {
        
    history.push(
        "/transection/details",
          item 
    )
}

  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "s_no",
      label: "S.no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "from_user",
      label: "Seller",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "to_user",
      label: "Buyer",
      options: {
        filter: true,
        sort: true,
      },
    },
   
    
    {
      name: "details",
      label: "Details",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        // material ui v4 only
        size: "small",
      };
    },
  
  };
  useEffect(() => {
    GetData();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center container  align-items-center">
        <div className="spinner-border text-secondary" role="status"></div>
      </div>
    );
  } else {
    return (
      <div className=" container ">
        <MUIDataTable
          title={"Decline Transection"}
          data={dataValues}
          columns={columns}
          options={options}
        />
       

    

     

      </div>
    );
  }
}

export default DeclineTransections;

 

 


