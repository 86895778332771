import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import API from "../../Apis/Api";
function AddMod(props) {
  const { open, close ,BGetData } = props;

  const [countryApi, setCountryApi] = useState([]);
  const [bankName, setBankName] = useState('');
  const [sellectedCountry, setSellectedCountry] = useState(
    JSON.stringify({
      id: 1,
      name: 'Pakin'
   
    })
  );
  const selectedCountry = JSON.parse(sellectedCountry);


  const AddBank = async () => {
    try {
      if (bankName.length >= 2) {
       
        const res = await API().postAddBank(
     
          selectedCountry.id,
          bankName
        )
     
        if(res.status ==true){
          close()
           BGetData()
        }
      }
    } catch (err) {

      console.log(err, "errbankedit")
    }
  }

  const GetData = async () => {
    try {
      const res = await API().getCountryList();
      setSellectedCountry(  JSON.stringify(res[0]))
     
      setCountryApi(res);
    } catch (err) {
      console.log(err, "editBank");
    }
  };

  

  const handleChange = (event) => {
    setSellectedCountry(event.target.value);
  };
  useEffect(() => {
    GetData();
  }, []);
  return (
    <div>
      <Modal open={open}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Bank</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                <div className="d-flex p-0 col-11 justify-content-between">
                  <h4 >Select Country</h4>
                  <select
                    style={{ width: "40%" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected>{selectedCountry.name}</option>
                    {countryApi.map((item) => {
                      return (
                        <>
                          {item.id != selectedCountry.id ? (
                            <>
                              <option
                                key={item.id}
                                value={JSON.stringify(item)}
                              >
                                {item.name}
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </select>
                </div>

                {bankName.length >= 2 ? (
                  <>  <h5 className="mt-3 col-11 p-0">Bank Name: </h5>

                  </>) : (
                  <>
                    <h5 className="mt-3 col-11 text-danger p-0">Bank Name: </h5>


                  </>
                )}

                <div
                  className=" border col-11  rounded  shadow-sm mx-auto "
                  style={{
                    // width: "90%",
                    height: "45px",

                  }}
                >

                  <input
                    value={bankName}
                    className="form-control mt-1 border-0 shadow-none  "


                    placeholder="Bank Name"
                    onChange={(e) => setBankName(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">

              <button
                style={{ width: "90%", fontSize: 25 }}
                type="button"
                className="btn btn-success mx-auto "
                onClick={() => AddBank()}
              >
                Bank Add
              </button>
            </div>
          </div>
        </div >
      </Modal >
    </div >
  );
}

export default AddMod;
