import React from 'react'

const ViewImage = (props) => {

    return (
        <div style={{ height: "98%", width: "90%", marginTop: "10px" }}>


            <img

                src={props.location.state}
                style={{ height: "100%", width: "100%" }}
                className="img-thumbnail "
                alt="Image"
            />

        </div>
    )
}

export default ViewImage
