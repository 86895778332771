import React from 'react'

import './App.scss';
import { UserContext } from "../src/Pages/Context";
import { useState, useMemo } from "react";




import Navigation from './Pages/Navigation.js';
function App() {

  const [new_Data, setNew_Data] = useState({ "type": 'testing' });
  // const value = useMemo(() => {(new_Data, setNew_Data)}, [new_Data, setNew_Data]);

  const value = useMemo(
    () => ({ new_Data, setNew_Data }),
    [new_Data, setNew_Data],
  );
  return (


    <UserContext.Provider value={value}>
      <div>

        <Navigation />

      </div>
    </UserContext.Provider>



  );
}

export default App;