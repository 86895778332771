import React from 'react'
import { NavLink } from 'react-router-dom';

const SidebarMenuItem = (props) => {
    return (
        <div className="sidebarMenuItem" style={{color:props.color}}>
            {
                (props.link)?(
                    <NavLink to={props.link}>
                        <li>
                            <div className="IconName">
                                <div className="icon">
                                { props.icon }
                                </div>
                                <div className="name">
                                    { props.children }
                                </div>
                            </div>
                        </li>
                    </NavLink>
                ):""
            }
            
        </div>
    )
}

export default SidebarMenuItem
