import React from 'react'
import { useHistory } from "react-router-dom";
import API from '../../Apis/Api';

import { FiCameraOff } from "react-icons/fi";

function UsersDetail(props) {

    // console.log(props.location.state,'props.location.state')
    const history = useHistory();

    const { profile_image, first_name, last_name, email, address, number, country_code, cnic, passport, country_name, cnic_front_image, cnic_back_image, cnin_with_face, passport_image, bill_image, user_verifyed, user_id, email_verify, phone_confirm } = props.location.state

    console.log(email_verify, 'email_verify')
    const UserVerification = async () => {
        try {
            const res = await API().getVerfication(
                user_id
            )
            history.replace(
                "/users/list",

            )

            console.log(res)
        } catch (err) {
            console.log(err, 'UserVerification')
        }
    }
    const UserImage = (item) => {

        history.push(
            "/users/image",
            item
        )
    }
    return (
        <div className="container">
            <div className=" col-xl-6 mx-auto border shadow rounded d-flex flex-wrap justify-content-center">
                <div className=" col-11 m-2  d-flex flex-wrap flex-column align-items-center">
                    {profile_image !== 'https://foloswap.com/public' ?
                        <div className=" border col-3  shadow-sm" style={{ borderRadius: '100px', height: "150px", width: "150px" }}>

                            <img
                                src={profile_image}
                                className="img-thumbnail rounded-circle "
                                alt="Profile"
                                style={{ height: "150px", width: "150px" }}
                            />
                        </div>
                        :
                        <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "150px", width: "150px" }}>
                            <FiCameraOff size={30} />
                        </div>
                    }

                    {user_verifyed == 0 ? (

                        <div className=" mt-2 bg-danger text-light border px-3 pt-2 text-center"
                            style={{ borderRadius: '40px' }} >
                            <h5>Unverified</h5>
                        </div>
                    ) : (<div className=" mt-2 bg-success text-light border px-3 pt-2 text-center"
                        style={{ borderRadius: '40px' }} >
                        <h5>Verified</h5>
                    </div>)}

                    <div className="col-7 mt-2  justify-content-center  d-flex">
                        <h5 style={{ marginTop: 10 }}>Name:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {first_name} {last_name}
                        </h5>
                    </div>
                    <div style={{}} className="col-9 justify-content-center align-items-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Email:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px", }}>{email}</h5>

                        {email_verify == 0 ?
                            <div style={{ height: 30, fontSize: 15, borderRadius: 100, backgroundColor: 'red', color: 'white' }} className=' mx-auto border row px-2 justify-content-center align-items-center' >unverified</div>
                            :

                            <div style={{ height: 30, fontSize: 15, borderRadius: 100, backgroundColor: 'green', color: 'white' }} className=' mx-auto border row px-2 justify-content-center align-items-center' >verified</div>
                        }

                    </div>
                    <div className="col-7 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Address:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{address}</h5>
                    </div>
                    <div className="col-8  justify-content-center align-items-center d-flex">

                        <h5 style={{ marginTop: 10 }}>Mobile:</h5>
                        <div className="d-flex">
                            <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                                {country_code} -
                            </h5>
                            <h5 style={{ color: "gray", marginTop: 10 }}>
                                {number}
                            </h5>
                        </div>
                        {/* {phone_confirm == 0 ?
                            <div style={{ height: 30, fontSize: 15, borderRadius: 100, backgroundColor: 'red', color: 'white' }} className=' mx-auto border row px-2 justify-content-center align-items-center' >unverified</div>
                            :
                            <div style={{ height: 30, fontSize: 15, borderRadius: 100, backgroundColor: 'green', color: 'white' }} className=' mx-auto border row px-2 justify-content-center align-items-center' >verified</div>
                        } */}
                    </div>


                    <div className="col-8 justify-content-center  d-flex">
                        <h5 style={{ marginTop: 10 }}>Cnic/Pasport Number:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {cnic}{passport}
                        </h5>
                    </div>
                    {/* <div className="col-7 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Pasport Number:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{passport}</h5>
                    </div> */}

                    <div className="col-7 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Country:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>{country_name}</h5>
                    </div>

                </div>
            </div>

            <div className="row col-12 justify-content-center  mt-2" style={{}}>

                <div style={{ height: 250, width: 330, marginTop: "10px" }} className='mx-5'>
                    {cnic_front_image === "No Image" ? (
                        <div className="border text-center d-flex justify-content-center align-items-center border-danger rounded text-danger" style={{ height: 250, width: 400 }} >
                            Image not uploaded yet
                        </div>
                    ) : (
                        <img onClick={() => UserImage(cnic_front_image)}
                            src={cnic_front_image}
                            style={{ height: 250, width: 400 }}
                            className="img-thumbnail "
                            alt="CNIC Frount Image" onMouseEnter={(e) => { e.target.style.cursor = 'pointer' }}
                        />
                    )}
                </div>


                {/* <div style={{ height: 250, width: 350, marginTop: "10px" }}>

                    {cnic_back_image === "No Image" ? (
                        <div className="border text-center d-flex justify-content-center align-items-center border-danger rounded text-danger" style={{ height: 250, width: 250 }} >
                            Image not uploaded yet
                        </div>
                    ) : (
                        <img onClick={() => UserImage(cnic_back_image)}
                            src={cnic_back_image}
                            style={{ height: 250, width: 350 }}
                            className="img-thumbnail "
                            alt="CNIC Back Image" onMouseEnter={(e) => { e.target.style.cursor = 'pointer' }}
                        />
                    )}
                </div> */}


                <div style={{ height: 250, width: 330, marginTop: "10px" }} className='mx-5'>

                    {cnin_with_face === "No Image" ? (
                        <div className="border text-center d-flex justify-content-center align-items-center border-danger rounded text-danger" style={{ height: 250, width: 400 }} >
                            Image not uploaded yet
                        </div>
                    ) : (
                        <img onClick={() => UserImage(cnin_with_face)}

                            src={cnin_with_face}
                            style={{ height: 250, width: 400 }}
                            className="img-thumbnail "
                            alt="CNIC Image With Holding" onMouseEnter={(e) => { e.target.style.cursor = 'pointer' }}
                        />
                    )}
                </div>


                {/* <div style={{ height: 250, width: 400, marginTop: "10px" , }} className='mx-5'>

                    {passport_image === "No Image" ? (
                        <div className="border text-center d-flex justify-content-center align-items-center border-danger rounded text-danger" style={{ height: 250, width: 400 }} >
                            Image not uploaded yet
                        </div>
                    ) : (

                        <img
                            onClick={() => UserImage(passport_image)}
                            src={passport_image}
                            style={{ height: 250, width: 400 }}
                            className="img-thumbnail "
                            alt="Pasport Image" onMouseEnter={(e) => { e.target.style.cursor = 'pointer' }}
                        />
                    )}
                </div> */}


                <div style={{ height: 250, width: 330, marginTop: "10px" }} className='mx-5'>

                    {bill_image === "No Image" ? (
                        <div className="border text-center d-flex justify-content-center align-items-center border-danger rounded text-danger" style={{ height: 250, width: 400 }} >
                            Image not uploaded yet
                        </div>
                    ) : (
                        <img
                            onClick={() => UserImage(bill_image)}
                            src={bill_image}
                            style={{ height: 250, width: 400 }}
                            className="img-thumbnail "
                            alt="Utility Bill Image" onMouseEnter={(e) => { e.target.style.cursor = 'pointer' }}
                        />
                    )}
                </div>
            </div>
            {user_verifyed == 0 ? (

                <div className="col-12  my-3 d-flex justify-content-center">

                    <button style={{ borderRadius: "20px" }} className="btn   px-5 btn-danger " onClick={UserVerification} >Verify</button>
                </div>
            ) : (<></>)}
        </div >
    )
}

export default UsersDetail
