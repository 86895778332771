import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import API from "../../Apis/Api";
function EditCrypto(props) {
    const { open, close, data, BGetData, } = props;

    const { id, name, symbol } = data

    const [bankName, setBankName] = useState('sssss');
    const [cryptoName, setCryptoName] = useState(name);
    const [cryptoSymbol, setCryptoSymbol] = useState(symbol);





    const EditCrypto = async () => {
        try {
            if (cryptoName.length >= 2 && cryptoSymbol.length >= 2) {
         
                const res = await API().postEditCrypto(
                    id,
                    cryptoName,
                    cryptoSymbol
                )

                if (res.status == true) {
                    close()
                    BGetData()
                }
            }
        } catch (err) {

            console.log(err, "errbankedit")
        }
    }

    useEffect(() => {

    }, []);
    return (
        <div>
            <Modal open={open}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>Edit Crypto</h2>
                            <AiOutlineClose
                                onClick={close}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">
                            <div className=" row justify-content-center">


                                {cryptoName.length >= 2 ? (
                                    <>  <h5 className="mt-3 col-11 p-0">Crypto Name: </h5>

                                    </>) : (
                                    <>
                                        <h5 className="mt-3 col-11 text-danger p-0">Crypto Name: </h5>


                                    </>
                                )}

                                <div
                                    className=" border col-11  rounded  shadow-sm mx-auto "
                                    style={{
                                        // width: "90%",
                                        height: "45px",

                                    }}
                                >

                                    <input
                                        value={cryptoName}
                                        className="form-control mt-1 border-0 shadow-none  "


                                        placeholder="Bank Name"
                                        onChange={(e) => setCryptoName(e.target.value)}
                                    />
                                </div>

                                {cryptoSymbol.length >= 2 ? (
                                    <>  <h5 className="mt-3 col-11 p-0">Crypto Symbol: </h5>

                                    </>) : (
                                    <>
                                        <h5 className="mt-3 col-11 text-danger p-0">Crypto Symbol: </h5>


                                    </>
                                )}

                                <div
                                    className=" border col-11  rounded  shadow-sm mx-auto "
                                    style={{
                                        // width: "90%",
                                        height: "45px",

                                    }}
                                >

                                    <input
                                        value={cryptoSymbol}
                                        className="form-control mt-1 border-0 shadow-none  "


                                        placeholder="Bank Name"
                                        onChange={(e) => setCryptoSymbol(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">

                            <button
                                style={{ width: "90%", fontSize: 25 }}
                                type="button"
                                className="btn btn-success mx-auto "
                                onClick={() => EditCrypto()}
                            >
                                Edit
                            </button>
                        </div>
                    </div>
                </div >
            </Modal >
        </div >
    );
}

export default EditCrypto;
