import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import API from "../../Apis/Api";
function AddPaymentMethodMod(props) {
  const { open, close ,BGetData } = props;

  const [paymentMethod, setPaymentMethod] = useState('');
 
 


  const AddPaymentMethod = async () => {
      
    try {
      if (paymentMethod.length >= 2) {
       
        const res = await API().postAddPaymentMethod(
     
       paymentMethod
        )
     
        if(res.status ==true){
          close()
           BGetData()
        }
      }
    } catch (err) {

      console.log(err, "errbankedit")
    }
  }

 

  

 
  return (
    <div>
      <Modal open={open}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Payment Method</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                
               

                {paymentMethod.length >= 2 ? (
                  <>  <h5 className="mt-3 col-11 p-0">Payment Method: </h5>

                  </>) : (
                  <>
                    <h5 className="mt-3 col-11 text-danger p-0">Payment Method: </h5>


                  </>
                )}

                <div
                  className=" border col-11  rounded  shadow-sm mx-auto "
                  style={{
                    // width: "90%",
                    height: "45px",

                  }}
                >

                  <input
                    value={paymentMethod}
                    className="form-control mt-1 border-0 shadow-none  "


                    placeholder="PaymentMethod"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div className="modal-footer">

              <button
                style={{ width: "90%", fontSize: 25 }}
                type="button"
                className="btn btn-success mx-auto "
                onClick={() => AddPaymentMethod()}
              >
               Add PaymentMethod
              </button>
            </div>
          </div>
        </div >
      </Modal >
    </div >
  );
}

export default AddPaymentMethodMod;
