import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { IconButton, Tooltip } from "@material-ui/core";


import Modal from "@material-ui/core/Modal";

import { useHistory } from "react-router-dom";


function UserList() {
    const [modalVisible, setModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [dataValues, setDataValues] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    
    const [sendAuthyLoader, setSendAuthyLoader] = useState(false);
    const [sendAuthyMessage, setSendAuthyMessage] = useState('');



    const history = useHistory();


    const GetData = async () => {
        try {
            setIsLoading(true)
            const res = await API().getVerfiedUsersList();
        
            let newData = [];
            res.users.map((item, index) => {
              
                newData.push({
                    s_no: index + 1,
                    isdisabled: (!item.is_disabled) ? "Enable" : "Disabled",
                    viewdetail: (

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-info  " onClick={() =>UserDetails(item) } > Detail</button>
                    ),
                    authaccount: (

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-danger" onClick={()=>OpenAuthyModal(item)} > Auth Account</button>
                    ),
                    disable: ((!item.is_disabled ?

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={() => DisableUser(item.user_id)} >Disable</button>
                        :
                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-danger " onClick={() => DisableUser(item.user_id)}>Enable</button>
                    )),
                    ...item,
                });

            });
            setIsLoading(false);

            setDataValues(newData);
        } catch (err) {
            console.log(err);
        }
    };
    const UserDetails = (item) => {
        
        history.push(
            "/users/detail",
              item 
        )
    }

    const DisableUser = async (id) => {


        try {
            const res = await API().getUserDisable(
                id
            )
            if (res.status == true) {
                GetData()

            }

        } catch (err) {
            console.log(err, "getDisableBank")
        }

    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        // {
        //     name: "s_no",
        //     label: "S.no",
        //     options: {
        //         filter: false,
        //         sort: true,
        //     },
        // },
        {
            name: "first_name",
            label: "FirstName",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "last_name",
            label: "LastName",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "email",
            label: "Email",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "isdisabled",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "viewdetail",
            label: "Detail",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "authaccount",
            label: "Auth Account",
            options: {
                filter: false,
                sort: true,
            },
        },

        {
            name: "disable",
            label: "Disable",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        setTableProps: () => {
            return {
                // material ui v4 only
                size: "small",
            };
        },

    };

const OpenAuthyModal=(item)=>{
    setModalVisible(true)
    console.log(item,'................................................')
    setUserDetails(item)
}

const SendAuthyCode=async()=>{
    setSendAuthyLoader(true)
    try {
        const res =await API().postAuthyCode(
            userDetails.country_code,
            userDetails.email,
            userDetails.number
        )
        setSendAuthyMessage('Send Successfully ')
        console.log(res,'res.............................................')
        setSendAuthyLoader(false)
    } catch (error) {
        
    }
}

    useEffect(() => {
        GetData();
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center container  align-items-center">
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    } else {
        return (
            <div className=" container ">
                <MUIDataTable
                    title={"Users List"}
                    data={dataValues}
                    columns={columns}
                    options={options}
                />



<Modal open={modalVisible}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Twilio Authy Account</h2>
              
            </div>
            <div className="modal-body">
                <div className="d-flex">
                    <h6 style={{color:'black'}}>Email:</h6>
                    <h6 style={{color:'gray',marginLeft:10}}>{userDetails.email}</h6>
                </div>
                <div className="d-flex">
                    <h6 style={{color:'black'}}>Phone Number:</h6>

                <div className="d-flex">
                    <h6 style={{color:'gray',marginLeft:10}}>{userDetails.country_code}</h6>
                    <h6 style={{color:'gray',}}>-{userDetails.number}</h6>
                </div>
                </div>
              
            </div>
<h6 style={{color:'red',textAlign:'center'}}>{sendAuthyMessage}</h6>
            <div className="modal-footer">

              <button
                style={{color:'white'  }}
                type="button"
                className="btn btn-info mx-auto "
                onClick={() => {setModalVisible(false)
                    setSendAuthyMessage('')
                }}
              >
            Close
              </button>
              <button
                style={{  }}
                type="button"
                className="btn btn-success mx-auto "
                onClick={SendAuthyCode}
              >
                  {sendAuthyLoader? 
                <div className="spinner-border text-secondary" role="status"></div>:
                   'Send Authy Code'
                  }
             
              </button>
            </div>
          </div>
        </div >
      </Modal >
            </div>
        );
    }
}

export default UserList;
