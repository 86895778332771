import React from 'react'

const SidebarHeader = (props) => {
    const style = {
        background:props.bgColor,
        color:props.color
    };
    return (
        <div className="sidebarHeader" style={style}>
            {
                (Array.isArray(props.children))?(
                    props.children.map((val)=>{
                        return val
                    })
                ):(
                    props.children
                )
            }
        </div>
    )
}

export default SidebarHeader
