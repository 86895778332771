import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";

// import EditMod from "./EditMod";
// import AddMod from "./AddMod";
import Modal from "@material-ui/core/Modal";


import { useHistory } from "react-router-dom";

function AdminWallet() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataValues, setDataValues] = useState([]);
  const history = useHistory();
  const [tranferCoinMod, setTranferCoinMod] = useState(false);
  const [walletDetal, setWalletDetal] = useState({});
  const [crypto, setCrypto] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [transferCoinBtnLoader, setTransferCoinBtnLoader] = useState('');
  const [msgModal, setMsgModal] = useState(false);
  const [msgRes, setMsgRes] = useState('');







  const GetData = async () => {
    try {
      setIsLoading(true)
      const res = await API().getAdminWalletDetils();
      let newData = [];

      res.data.map((item, index) => {

        console.log(item.wallet_balance.balance.final_balance, 'item.wallet_balance.balance.final_bal');

        newData.push({
          s_no: index + 1,
          waletaddress: item.wallet_balance.balance.address,
          FinalBalance: (item.currecny == 'USDT' ? item.wallet_balance.balance.final_balance : item.wallet_balance.balance.final_balance / 100000000),

          FundTransfer: ((
            <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={() => FundTransferModalOpen(item)} >Transfer</button>
          )),

          ...item,
        });

      });
      setIsLoading(false);

      setDataValues(newData);
    } catch (err) {
      console.log(err);
    }
  };

  const FundTransferModalOpen = async (data) => {
    setTranferCoinMod(true)
    setWalletDetal(data)
  }

  const FundTransferModalClose = () => {
    setTranferCoinMod(false)
    setWalletAddress('')
    setCrypto('')

  }

  const Transfer = async () => {
    setTransferCoinBtnLoader(true)

    try {
      const ress = await API().postTransferCrypto(
        walletDetal.wallet_id,
        parseFloat(crypto),
        walletAddress
      )
      console.log(walletDetal.wallet_id,
        parseFloat(crypto),
        walletAddress, 'lklkkkkkkkkkkkkkkkkkkk');

        console.log(ress, 'lfund_transfer_admin');
        if (ress.status) {
      
          setMsgModal(true)
          FundTransferModalClose()
          setMsgRes(ress.message)
          
          setTimeout(() => {
            GetData()
            setMsgModal(false)
            setTransferCoinBtnLoader(false)
          }, 3000);
      }else{
        FundTransferModalClose()
        setMsgModal(true)
        setMsgRes(ress.message)
        setTransferCoinBtnLoader(false)

        setTimeout(() => {
          setMsgModal(false)
        }, 3000);

      }
      // setTransferCoinBtnLoader(false)


    } catch (err) { console.log(err, 'errrrfundtransection') }

  }


  const columns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: "s_no",
      label: "S.no",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "waletaddress",
      label: "Wallet Address",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "currecny",
      label: "Seller",
      options: {
        filter: false,
        sort: true,
      },
    },


    {
      name: "FinalBalance",
      label: "Balance",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "FundTransfer",
      label: "Fund Transfer",
      options: {
        filter: true,
        sort: true,
      },
    },

  ];

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    filter: false,
    viewColumns: false,
    selectableRows: "single",
    selectableRowsHideCheckboxes: true,
    setTableProps: () => {
      return {
        // material ui v4 only
        size: "small",
      };
    },

  };
  useEffect(() => {
    GetData();
  }, []);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center container  align-items-center">
        <div className="spinner-border text-secondary" role="status"></div>
      </div>
    );
  } else {
    return (
      <div className=" container ">


        <MUIDataTable
          title={"My Wallet"}
          data={dataValues}
          columns={columns}
          options={options}
        />


        {/*####################### 
        ##### Tranfer Coin Mod ##### 
        #######################*/}

        <Modal open={tranferCoinMod}>
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              <div className="modal-header">
                <h1> Transfer  {walletDetal.currecny} </h1>
              </div>
              <div className="modal-body">
                <div className="col-12 row justify-content-center">
                  <div className="mt-2  d-flex justify-content-between align-items-center ">
                  </div>

                  <h6> {walletDetal.currecny}:</h6>
                  <div
                    className=" border  rounded  shadow-sm mx-auto "
                    style={{
                      width: "95%",
                      height: "45px",
                    }}
                  >
                    <input
                      className="form-control mt-1 border-0 shadow-none  "
                      value={crypto}
                      onChange={(e) => {
                        if (!isNaN(e.target.value)) {
                          setCrypto(e.target.value);
                        }
                      }}
                      placeholder="0"
                      style={{
                        width: "100%",

                      }}
                    />
                  </div>

                  <h6 className="mt-2">Wallet Address:</h6>
                  <div
                    className=" border  rounded  shadow-sm mx-auto "
                    style={{
                      width: "95%",
                      height: "45px",

                    }}
                  >
                    <input
                      className="form-control mt-1 border-0 shadow-none  "
                      value={walletAddress}
                      onChange={(e) => {
                        setWalletAddress(e.target.value);
                      }}
                      placeholder="Wallet Address"
                      style={{
                        width: "100%",
                      }}
                    />
                  </div>

                </div>
              </div>
              <div className="modal-footer ">
                <button
                  onClick={FundTransferModalClose}
                  type="button"
                  className="btn px-4 border-0 shadow-none rounded-pill btn-danger"
                  style={{}}
                >
                  Close
                </button>

                {walletAddress.length >= 2 && crypto.length >= 1 ?
                  <button
                    onClick={Transfer}
                    style={{ width: '30%', padding: 6, backgroundColor: 'rgb(4,134,62)', borderRadius: 50, color: 'white' }}
                    type="button"
                    className=" border-0 "

                  >
                    {transferCoinBtnLoader ?
                      <div className="spinner-border mt-1 " style={{ height: 20, width: 20, color: '#cbc8c8' }} role="status"></div>
                      :
                      'Transfer'
                    }
                  </button>
                  :
                  <button disabled style={{ width: '30%', padding: 6, backgroundColor: 'rgba(4,134,62,0.5)', borderRadius: 50 }} className="border-0">Transfer</button>

                }


              </div>
            </div>
          </div>
        </Modal>

          {/*################# 
          ##### TMsg Mod ##### 
          #################*/}

<Modal open={msgModal}>
          <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
              
              <div className="modal-body">
            {msgRes}
              </div>
              
            </div>
          </div>
        </Modal>


      </div>


    );
  }
}

export default AdminWallet;





