const Api = "https://foloswap.com/api/admin"; // SYSTEM ACCESS IP


const LoginApi = "https://foloswap.com/api";
const SwapApi = "https://foloswap.com/api/SwapAdmin";
const msg = 'https://f557-162-241-121-1.ngrok.io'

const fetchJSONPOSTwithbodywithouttoken = async ({ url, body }) => {
  let res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Access-Control-Allow-Origin": "*",
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return res.json();
};

const fetchJSON = async ({ url }) => {
  let res;
  const data = await JSON.parse(localStorage.getItem("userData"));

  // const data = "315|C1DgmLVn8kCxwHN6ioyETx5jxjimMYjy5QXV3wxS";
  res = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      'Accept': 'application/json',
      // prettier-ignore
      'Authorization': `Bearer ${data.token}`,
    },
  });

  return res.json();
};

const fetchJSONPOSTwithbody = async ({ url, body }) => {
  let res;


  const data = await JSON.parse(localStorage.getItem("userData"));
  res = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      // prettier-ignore
      'Authorization': `Bearer ${data.token}`,
      // prettier-ignore
      'Accept': 'application/json',
    },
    body: JSON.stringify(body),
  });

  return res.json();
};

// #################################
// ######## LoginAPI Apies #########
// #################################


export const LoginAPI = (credential) => ({


  postMessage: (
    tid

  ) =>
  fetchJSONPOSTwithbodywithouttoken({
      url: `${msg}/get_messages`,
      body: {
        room_id: tid
      },
    }),
  signin: (_username, _password) =>
    fetchJSONPOSTwithbodywithouttoken({
      url: `${LoginApi}/user/login`,
      body: { email: _username, password: _password },
    }),

  postEditProfile: (
    id,
    firstName,
    lastName,
    profileImage,
    profileImageName,

  ) =>
    fetchJSONPOSTwithbody({
      url: `${LoginApi}/update_profile/${id}`,
      body: {
        first_name: firstName,
        last_name: lastName,

        image_name: profileImageName,
        profile_image: profileImage,

      },
    }),
  getUserProfileDetail: (id) =>
    fetchJSON({ url: `${LoginApi}/user_profile_detail/${id}` }),
  postChangePassword: (id, password, changePassword) =>
    fetchJSONPOSTwithbody({
      url: `${LoginApi}/update_password/${id}`,
      body: {
        old_password: password,
        new_password: changePassword,
      },
    }),
})
const API = (credentials) => ({
  // ############################
  // ######## Get Apies #########
  // ############################
  getPercentage: () => fetchJSON({ url: `${LoginApi}/percentage` }),

  getDisputeSwapRequestList: () => fetchJSON({ url: `${SwapApi}/dispute_request_list` }),
  
  getSwapTransectionDeclined: () => fetchJSON({ url: `${SwapApi}/swap_transection_declined` }),

  getSwapUnaprovedTransection: () => fetchJSON({ url: `${SwapApi}/swap_unaproved_transection` }),
  getSwapAprovedTransection: () => fetchJSON({ url: `${SwapApi}/swap_aproved_transection` }),
  
  getAdminWalletDetils: () => fetchJSON({ url: `${Api}/Admin_wallet_deatils` }),
  getDeclineTransectionList: () => fetchJSON({ url: `${Api}/transection_declined` }),
  getDeclineTransection: (tansection_id) => fetchJSON({ url: `${Api}/DeclineTransection/${tansection_id}` }),
  
  getcurrency: () => fetchJSON({ url: `${LoginApi}/currency_list` }),
  getcrypto: () => fetchJSON({ url: `${LoginApi}/crypto_list` }),
  getAprovedTransection: () => fetchJSON({ url: `${Api}/aproved_transection` }),
  getUnAprovedTransection: () => fetchJSON({ url: `${Api}/un_aproved_transection` }),
  getDisputeRequestList: () => fetchJSON({ url: `${Api}/dispute_request_list` }),
  getUnVerfiedUsersList: () => fetchJSON({ url: `${Api}/unverfied_users` }),
  getVerfiedUsersList: () => fetchJSON({ url: `${Api}/verfied_users` }),
  getVerfication: (id) => fetchJSON({ url: `${Api}/user_verify/${id}` }),
  getUserDisable: (id) => fetchJSON({ url: `${Api}/user_is_disable/${id}` }),
  getCurrancyList: () => fetchJSON({ url: `${Api}/currency_list` }),
  getCryptoList: () => fetchJSON({ url: `${Api}/crypto_list` }),
  getPaymentMethodList: () => fetchJSON({ url: `${Api}/payment_methods` }),
  getBanksList: () => fetchJSON({ url: `${Api}/banks` }),
  getCountryList: () => fetchJSON({ url: `${Api}/country_list` }),
  getAppCountryList: () => fetchJSON({ url: `${LoginApi}/country_get` }),
  
  getDeleteBank: (id) => fetchJSON({ url: `${Api}/delete_bank/${id}` }),
  getDisableBank: (id) => fetchJSON({ url: `${Api}/disable_bank/${id}` }),
  getDeleteCountry: (id) => fetchJSON({ url: `${Api}/delete_country/${id}` }),
  getDisableCountry: (id) => fetchJSON({ url: `${Api}/disable_country/${id}` }),
  getDeleteCypto: (id) => fetchJSON({ url: `${Api}/delete_crpto/${id}` }),
  getDisableCypto: (id) => fetchJSON({ url: `${Api}/disable_crpto/${id}` }),
  getDeletePaymentMethod: (id) => fetchJSON({ url: `${Api}/delete_PaymentMethod/${id}` }),
  getDisablePaymentMethod: (id) => fetchJSON({ url: `${Api}/disable_PaymentMethod/${id}` }),
  getDeleteCurrency: (id) => fetchJSON({ url: `${Api}/delete_currency/${id}` }),
  getDisableCurrency: (id) => fetchJSON({ url: `${Api}/disable_currency/${id}` }),
  getAllAds: () => fetchJSON({ url: `${Api}/all_ads` }),
  getDisableAds: (id) => fetchJSON({ url: `${Api}/disable_add_admin/${id}` }),
  getBidRequest: (id) => fetchJSON({ url: `${Api}/get_bid_request/${id}` }),
  getLimits: () => fetchJSON({ url: `${Api}/get_limit` }),
  getDeleteLimits: (id) => fetchJSON({ url: `${Api}/delete_limit/${id}` }),
  // getEditLimit: (id) => fetchJSON({ url: `${Api}/edit_limit/${id}` }),

  // ############################
  // ######## Post Apies ########
  // ############################
  
  postAuthyCode: (
    code,
    email,
    number
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/authy_code_`,
      body: {
        country_code : code,
        email : email,
        number: number
        
      },
    }),
  postEditPercentage: (
    p2ptransection,
    direktransfer
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_percentage`,
      body: {
        p2p_transection:p2ptransection , 
        direk_transfer:direktransfer
        
      },
    }),


  postAcceptTransection: (
    tansection_id,
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/accept_transection/${tansection_id}`,
      body: {
        to_user: 'bid_user_id',
        
      },
    }),

  getAddLimit: (
    priceType,
    selectedCrypto_id,
    selectedCurrency_id,
    cryptoLimit,
    currencyLimit
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/add_limit`,
      body: {
        crypto_id:selectedCrypto_id,
        crypto_limit:cryptoLimit,
        price_limit:currencyLimit,
        type:priceType,
        currency_id:selectedCurrency_id,
      },
    }),
  getEditLimit: (
    limit_id,
    selectedCrypto_id,
    selectedCurrency_id,
    cryptoLimit,
    currencyLimit
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_limit/${limit_id}`,
      body: {
        crypto_id: selectedCrypto_id,
        crypto_limit: cryptoLimit,
        price_limit: currencyLimit,
        currency_id: selectedCurrency_id,
      },
    }),
  postEditBank: (id,
    countryId,
    bankName) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_bank/${id}`,
      body: {
        country_id: countryId,
        bank_name: bankName
      },
    }),
  postAddBank: (
    CountryId,
    bankName
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/add_bank`,
      body: {
        country_id: CountryId,
        bank_name: bankName
      },
    }),
  postAddCountry: (
    countryName,
    countryCode,

  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/add_country`,
      body: {
        country_name: countryName,
        code: countryCode
      },
    }),
  postEditCountry: (
    id,
    Countryname,
    CountryCode
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_country/${id}`,
      body: {
        country_name: Countryname,
        code: CountryCode
      },
    }),

  postEditCrypto: (
    id,
    cryptoName,
    cryptoSymbol
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_crpto/${id}`,
      body: {
        crpto_name: cryptoName,
        symbol: cryptoSymbol
      },
    }),
  postAddCurrency: (
    id,
    currancyName,
    currancySymbol
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/add_currency`,
      body: {
        currency_name: currancyName,
        symbol: currancySymbol,
        country_id: id,
      },
    }),
  postEditCurrency: (
    id,
    currancyName,
    currancySymbol,
    CountryId
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_currency/${id}`,
      body: {
        currency_name: currancyName,
        symbol: currancySymbol,
        country_id: CountryId,
      },
    }),
  postAddPaymentMethod: (
    paymentMethod
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/add_PaymentMethod`,
      body: {
        method_name: paymentMethod
      },
    }),
  postEditPaymentMethod: (
    id,
    paymentMethod
  ) =>
    fetchJSONPOSTwithbody({
      url: `${Api}/edit_PaymentMethod/${id}`,
      body: {
        method_name: paymentMethod
      },
    }),

    postTransferCrypto: (
      walletId,
      Amount,
      walletAddress
  
    ) =>
      fetchJSONPOSTwithbody({
        url: `${LoginApi}/fund_transfer_admin`,
        body: {
          wallet_id: walletId,
          amount: Amount,
          wallet_address: walletAddress
        },
      }),
});

export default API;
