import React from 'react'
import Drawer from "../Drawer"
import Navbar from "../Navbar"

const NavWithSide = (props) => {
    return (
        <div className="wrapper">
            <Drawer />
            <div className="main">
                <Navbar />
                <div className="body">
                    {props.children}
                </div>
            </div>

        </div>
    )
}

export default NavWithSide
