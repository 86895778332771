import { FaListUl, FaPlus, FaSalesforce, FaUserTie,FaSortAmountUp } from "react-icons/fa";
import { GiWallet, GiWorld, GiReturnArrow,GiPayMoney } from "react-icons/gi";
import { CgDollar } from "react-icons/cg";
import { FcMoneyTransfer } from "react-icons/fc";
import { BiPurchaseTag } from "react-icons/bi";
import { RiUserShared2Fill } from "react-icons/ri";
import { BsBank, BsFillAwardFill } from "react-icons/bs";
import { SiStorybook, SiStoryblok } from "react-icons/si";
import { FiSettings } from "react-icons/fi";

const SidebarData = [

    // {
    //     link: "/",
    //     // icon: <FcMoneyTransfer />,
    //     // name: "Dashboard",
    //   },
  {
    icon: <BsBank />,
    name: "Banks",
    dropdown: [
      
      {
        link: "/banks/list",
        icon: <FaListUl />,
        name: "List",
      },
    ],
  },
  {
    icon: <GiWorld />,
    name: "Countries",
    dropdown: [
    
      {
        link: "/countries/list",
        icon: <FaListUl />,
        name: "List",
      },
    ],
  },

  {
    icon: <FaSalesforce />,
    name: "Ads",
    dropdown: [
      {
        link: "/ads/buyingrequest",
        icon: <BiPurchaseTag />,
        name: "Buying Request",
      },
      {
        link: "/ads/sellingrequest",
        icon: <GiReturnArrow />,
        name: "Selling Request",
      },
    ],
  },
  {
    icon: <RiUserShared2Fill />,
    name: "Users",
    dropdown: [
      {
        link: "/users/verification",
        icon: <FaUserTie />,
        name: "User Verification",
      },
      {
        link: "/users/add",
        icon: <FaPlus />,
        name: "Add",
      },
      {
        link: "/users/list",
        icon: <FaListUl />,
        name: "List",
      },
      {
        link: "/users/wallet",
        icon: <GiWallet />,
        name: "Wallet",
      },
    ],
  },
  {
    icon: <BsFillAwardFill />,
    name: "Crypto Currency",
    dropdown: [
  
      {
        link: "/crypto/list",
        icon: <FaListUl />,
        name: "List",
      },
    ],
  },

  {
    icon: <CgDollar />,
    name: "FIAT Currency",
    dropdown: [
    
      {
        link: "/currancy/list",
        icon: <FaListUl />,
        name: "List",
      },
    ],
  },
  {
    icon: <GiPayMoney />,
    name: "Payment Method",
    dropdown: [
    
      {
        link: "/paymentmethod/list",
        icon: <FaListUl />,
        name: "List",
      },
    ],
  },
  {
    icon: <FaSortAmountUp />,
    name: "Amount Limit",
    dropdown: [
    
      {
        link: "/amountlimit/buylimit",
        icon: <SiStoryblok />,
        name: "Buying Limit",
      },
      {
        link: "/amountlimit/selllimit",
        icon: <SiStorybook />,
        name: "Selling Limit",
      },
    ],
  },
  {
    
    icon: <GiWallet />,
    name: "My Wallet",
    dropdown: [
    
      {
        link: "/mywallet",
        icon: <FaListUl />,
        name: "List",
      },]
  },

  {
    icon: <FcMoneyTransfer />,
    name: "Transaction",
    dropdown: [
    
      {
        link: "/transection/aproved",
        icon: <SiStoryblok />,
        name: "Aproved ",
      },
      {
        link: "/transection/unaproved",
        icon: <SiStorybook />,
        name: "Unaproved",
      },
      {
        link: "/transection/dispute",
        icon: <SiStorybook />,
        name: "Dispute",
      },
      {
        link: "/transection/decline",
        icon: <SiStorybook />,
        name: "Decline",
      },
    ],
  },

  {
    icon: <FcMoneyTransfer />,
    name: "Swap Transaction",
    dropdown: [
    
      {
        link: "/swaptransection/aproved",
        icon: <SiStoryblok />,
        name: "Aproved ",
      },
      {
        link: "/swaptransection/unaprovedtransection",
        icon: <SiStorybook />,
        name: "Unaproved",
      },
      {
        link: "/swaptransection/dispute",
        icon: <SiStorybook />,
        name: "Dispute",
      },
      {
        link: "/swaptransection/decline",
        icon: <SiStorybook />,
        name: "Decline",
      },
    ],
  },
 
    
      {
        link: "/appsetting",
        icon: <FiSettings  />,
        name: "App Setting ",
      },
    
 
];

export default SidebarData;
