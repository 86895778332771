import React from 'react'

function AdsBBReq() {
    return (
        <div>
            <h1>Buying Bids Req</h1>
        </div>
    )
}

export default AdsBBReq
