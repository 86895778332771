import React, { useRef, useState, useEffect } from "react";
import {
    Sidebar,
    SidebarHeader,
    SidebarContent,
    SidebarMenu,
    SidebarMenuItem,
    SidebarSubMenu,
} from "./Drawer/index";
import ProfileImage from "../images/profile_img.jpg";
import SidebarData from "./Drawer/SidebarData";
import img from "../images/digital-1661823_1920.jpg";
import Modal from "@material-ui/core/Modal";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { LoginAPI } from "../Apis/Api";

import { VscSettingsGear } from "react-icons/vsc";
const Drawer = () => {

    const UserData = JSON.parse(localStorage.getItem("userData"));
    const { id } = UserData.data
   
    const [isloading, setIsloading] = useState(true);
    const [responceMessage, setResponceMessage] = useState('');
    const [apiUserDetails, setApiUserDetails] = useState({});
    const {
        first_name,
        last_name,
        email,
        image,

    } = apiUserDetails;

    const [values, setValues] = useState({
        image: null,
        name: null,
    });

    const [isloadin, setIsloadin] = useState(true);
    const [EditProfileModal, setEditProfileModal] = useState(false);
    const [changePasswordModal, setChangePasswordModal] = useState(false);

    const [firstName, setFirstName] = useState('test');
    const [lastName, setLastName] = useState('test');

    const [errors, setErrors] = useState("");
    const [selectedImage, setSelectedImage] = useState({
        image: null,
        name: null,
    });
    const [password, setPassword] = useState("");

    const [changePassword, setChangePassword] = useState("");
    const [visiblepass, Setvisiblepass] = useState({
        Type: "password",
        iconName: BsEyeSlash,
    });
    const [visibleChangepass, SetvisibleChangepass] = useState({
        Type: "password",
        iconName: BsEyeSlash,
    });


    const ImageRef = useRef();
    const fileOpen = () => {
        const trigger = ImageRef.current;
        trigger.click();
    };
    const handleChangeImage = (e) => {
        const { value, files } = e.target;
        let ddddd = value.split("\\");
        ddddd = ddddd[ddddd.length - 1];
 
        const file = files[0];
        let ext = value.split(".");
    
        ext = ext[ext.length - 1];
        if (
            ext === "jpg" ||
            ext === "JPG" ||
            ext === "jpeg" ||
            ext === "bmp" ||
            ext === "png"
        ) {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: { error: false, text: "" },
                };
            });
            const reader = new FileReader();
            reader.onload = (e) => {
                const FileasDataUrl = e.target.result;
            
                const base64 = FileasDataUrl.split(",");
               
                const base64Encoded = base64[base64.length - 1];
                setSelectedImage({ name: ddddd, image: base64Encoded });

                setValues({ image: FileasDataUrl, name: ddddd });
            };
            reader.readAsDataURL(file);
        } else {
            setErrors((prev) => {
                return {
                    ...prev,
                    image: {
                        error: true,
                        text: "Only jpg, jpeg, bmp and png are allowed",
                    },
                };
            });
        }
    };
    const EditProfileModOpen = () => {
        setEditProfileModal(true);
    };
    const EditProfileModClose = () => {
        setEditProfileModal(false);
        setFirstName(first_name)
        setLastName(last_name)
        setValues({ image: null })
    };
    const ChangePasswordModOpen = () => {
        setChangePasswordModal(true);
    };
    const ChangePasswordModClose = () => {
        setChangePasswordModal(false);
        setPassword('')
        setChangePassword('')
        
        setResponceMessage('')
    };




    const GetData = async () => {
        try {
            const respons = await LoginAPI().getUserProfileDetail(id);

            
            setFirstName(respons.user_detail.first_name)
            setLastName(respons.user_detail.last_name)
            setApiUserDetails(respons.user_detail);
            setIsloading(false);
        } catch (err) {
            console.log(err, "errSett");
        }
    };


    const updateProfile = async () => {

   
        if (firstName.length >= 2 && lastName.length >= 2) {

            try {

                const responce = await LoginAPI().postEditProfile(
                    id,
                    firstName,
                    lastName,
                    selectedImage.image,
                    selectedImage.name,
                );

                if (responce.message === 'Profile Updated') {
                    GetData()
                    EditProfileModClose()
                }
                

            } catch (err) {
                console.log(err, 'errEdit')
            }
        } else (console.log('jjjj'))
    }
    const UpdatePassword = async () => {


        if (password.length >= 6 && changePassword.length >= 6) {
            try {

                const response = await LoginAPI().postChangePassword(
                    id,
                    password,
                    changePassword
                );

               

                if (response.message === "Password Updated") {
                  
                    
                }
                setResponceMessage(response.message)
            } catch (err) {
                console.log(err, "chngpasserr");
            }
        }
    };

    useEffect(() => {
        GetData();
    }, []);
    return (
        <Sidebar bgColor="#000000" image={img}>
            <SidebarHeader bgColor="#00000000" color="white">
                Folo Swap
            </SidebarHeader>
            <SidebarContent>
                <div className="profile">

                    <div className="profile_image">
                        {/* {isloadin ? (
                            <div className=" justify-content-center  align-items-center">
                                <div className="spinner-border text-secondary" role="status"></div>
                            </div>
                        ) : ( */}


                        <img
                            className="profile_img"
                            src={image}
                            alt="Profile"
                        />
                        {/* // )
                        // } */}
                    </div>
                    <div className="profile_user_name">
                        {/* {isloadin ? (
                            <div className=" justify-content-center  align-items-center">
                                <div className="spinner-border text-secondary" role="status"></div>
                            </div>
                        ) : ( */}
                        <span>{first_name}</span>
                        <span style={{ marginLeft: 5 }}>{last_name}</span>
                        {/* // )} */}
                        <p>
                            <div className="online"></div> Admin
                            <div className="dropend">
                                <div
                                    style={{ cursor: "pointer" }}
                                    id="dropdownMenuButton1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                >
                                    <VscSettingsGear size={20} style={{ marginLeft: "5" }} />
                                </div>
                                <ul
                                    className="dropdown-menu"
                                    aria-labelledby="dropdownMenuButton1"
                                >
                                    <li>
                                        <a
                                            className="dropdown-item"
                                            style={{ cursor: "pointer" }}
                                            onClick={EditProfileModOpen}
                                        >
                                            Edit Profile
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={ChangePasswordModOpen} style={{ cursor: "pointer" }}>
                                            Change Password
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </p>
                    </div>
                </div>
                <SidebarMenu>
                    {SidebarData.map((val, index) => {
                        return val.hasOwnProperty("link") ? (
                            <SidebarMenuItem icon={val.icon} key={index} link={val.link}>
                                {val.name}
                            </SidebarMenuItem>
                        ) : val.hasOwnProperty("dropdown") ? (
                            <SidebarSubMenu
                                title={val.name}
                                id={`li${index}`}
                                key={index}
                                icon={val.icon}
                            >
                                {val.dropdown.map((dropdownVal, dropdownIndex) => {
                                    return (
                                        <SidebarMenuItem
                                            icon={dropdownVal.icon}
                                            key={dropdownIndex}
                                            link={dropdownVal.link}
                                        >
                                            {dropdownVal.name}
                                        </SidebarMenuItem>
                                    );
                                })}
                            </SidebarSubMenu>
                        ) : (
                            ""
                        );
                    })}
                </SidebarMenu>
            </SidebarContent>

            {/*###############################
            ####### Edit Profile Modal #######
            ############################## */}
            <Modal open={EditProfileModal}>
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div className="modal-header">
                            <h3> Edit Profile</h3>
                        </div>
                        <div className="modal-body">
                            <div className=" d-flex ">
                                <input
                                    ref={ImageRef}
                                    onChange={(e) => handleChangeImage(e)}
                                    className="d-none"
                                    type="file"
                                />
                                {values.image === null ? (
                                    <>
                                        <img
                                            onClick={fileOpen}
                                            src={image}
                                            style={{ height: 150, width: 150, cursor: "pointer" }}
                                            className="img-thumbnail rounded-circle"
                                            alt="Profile"
                                        />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            onClick={fileOpen}
                                            src={values.image}
                                            style={{ height: 150, width: 150, cursor: "pointer" }}
                                            className="img-thumbnail rounded-circle"
                                            alt="Profile"
                                        />
                                    </>
                                )}

                                <div className=" mt-4 ">
                                    <h3 style={{ color: "gray", marginTop: 10, marginLeft: 10 }}>
                                        {first_name} {last_name}
                                    </h3>
                                    <h5 style={{ color: "gray", marginLeft: 10 }}>
                                        {email}
                                    </h5>
                                </div>
                            </div>
                            <div className="mt-2">
                                {firstName.length >= 2 ? (
                                    <>
                                        <h6>Name:</h6>
                                        <input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            className="form-control "
                                            style={{ width: "100%" }}
                                        ></input>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="text-danger">Name:</h6>
                                        <input
                                            value={firstName}
                                            onChange={(e) => setFirstName(e.target.value)}
                                            placeholder="Fill This Feild"
                                            className="form-control border-danger"
                                            style={{ width: "100%" }}
                                        ></input>
                                    </>
                                )}
                            </div>
                            <div className="mt-2">
                                {lastName.length >= 2 ? (
                                    <>
                                        <h6>Last Name:</h6>
                                        <input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            className="form-control "
                                            style={{ width: "100%" }}
                                        ></input>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="text-danger">Last Name:</h6>
                                        <input
                                            value={lastName}
                                            onChange={(e) => setLastName(e.target.value)}
                                            placeholder="Fill This Feild  "
                                            className="form-control border-danger"
                                            style={{ width: "100%" }}
                                        ></input>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer ">
                            <button
                                style={{ fontSize: 16, borderRadius: 20 }}
                                onClick={() => EditProfileModClose()}
                                type="button"
                                className="btn  btn-outline-secondary"
                            >
                                Close
                            </button>
                            <button
                                onClick={updateProfile}
                                style={{ fontSize: 16, borderRadius: 20 }}
                                type="button"
                                className="btn  btn-outline-success"
                            >
                                Update Profile
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/*###############################
            ###### Change Password Modal #####
            ############################## */}
            <Modal open={changePasswordModal}>
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div className="modal-header">
                            <h3> Change Password</h3>
                        </div>
                        <div className="modal-body">
                            {password.length >= 6 ? (
                                <>
                                    <h6 className="mt-3">Old Password:</h6>
                                    <div
                                        className="  d-flex justify-content-between border shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",

                                            paddingTop: 10,
                                            paddingBottom: 10,

                                            borderRadius: 5,
                                        }}
                                    >
                                        <input
                                            value={password}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                            type={visiblepass.Type}
                                            placeholder="Enter Old Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <visiblepass.iconName
                                            size={30}
                                            style={{ marginRight: "5%" }}
                                            onClick={() => {
                                                visiblepass.Type === "password"
                                                    ? Setvisiblepass({
                                                        Type: "text",
                                                        iconName: BsEye,
                                                    })
                                                    : Setvisiblepass({
                                                        Type: "password",
                                                        iconName: BsEyeSlash,
                                                    });
                                            }}
                                        />
                                    </div>
                                        <p className="text-center text-danger">{responceMessage}</p>
                                </>
                            ) : (
                                <>
                                    <h6 className=" mt-3 text-danger">Old Password:</h6>
                                    <div
                                        className="  d-flex justify-content-between border border-danger shadow-sm mx-auto "
                                        style={{
                                            width: "100%",
                                            height: "45px",

                                            paddingTop: 10,
                                            paddingBottom: 10,

                                            borderRadius: 5,
                                        }}
                                    >
                                        <input
                                            value={password}
                                            className="form-control border-0 shadow-none  "
                                            style={{
                                                width: "85%",
                                                paddingLeft: 20,
                                            }}
                                            type={visiblepass.Type}
                                            placeholder="Enter Old Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        <visiblepass.iconName
                                            size={30}
                                            style={{ marginRight: "5%" }}
                                            onClick={() => {
                                                visiblepass.Type === "password"
                                                    ? Setvisiblepass({
                                                        Type: "text",
                                                        iconName: BsEye,
                                                    })
                                                    : Setvisiblepass({
                                                        Type: "password",
                                                        iconName: BsEyeSlash,
                                                    });
                                            }}
                                        />
                                    </div>
                                </>
                            )}

                            <div className="mt-2">
                                {changePassword.length >= 6 ? (
                                    <>
                                        <h6>New Password:</h6>
                                        <div
                                            className="  d-flex justify-content-between border shadow-sm mx-auto "
                                            style={{
                                                width: "100%",
                                                height: "45px",

                                                paddingTop: 10,
                                                paddingBottom: 10,

                                                borderRadius: 5,
                                            }}
                                        >
                                            <input
                                                value={changePassword}
                                                className="form-control border-0 shadow-none  "
                                                style={{
                                                    width: "85%",
                                                    paddingLeft: 20,
                                                }}
                                                type={visibleChangepass.Type}
                                                placeholder="Enter New Password"
                                                onChange={(e) => setChangePassword(e.target.value)}
                                            />
                                            <visibleChangepass.iconName
                                                size={30}
                                                style={{ marginRight: "5%" }}
                                                onClick={() => {
                                                    visibleChangepass.Type === "password"
                                                        ? SetvisibleChangepass({
                                                            Type: "text",
                                                            iconName: BsEye,
                                                        })
                                                        : SetvisibleChangepass({
                                                            Type: "password",
                                                            iconName: BsEyeSlash,
                                                        });
                                                }}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <h6 className="text-danger">New Password:</h6>
                                        <div
                                            className="  d-flex justify-content-between border border-danger shadow-sm mx-auto "
                                            style={{
                                                width: "100%",
                                                height: "45px",

                                                paddingTop: 10,
                                                paddingBottom: 10,

                                                borderRadius: 5,
                                            }}
                                        >
                                            <input
                                                value={changePassword}
                                                className="form-control border-0 shadow-none  "
                                                style={{
                                                    width: "85%",
                                                    paddingLeft: 20,
                                                }}
                                                type={visibleChangepass.Type}
                                                placeholder="Enter New Password"
                                                onChange={(e) => setChangePassword(e.target.value)}
                                            />
                                            <visibleChangepass.iconName
                                                size={30}
                                                style={{ marginRight: "5%" }}
                                                onClick={() => {
                                                    visibleChangepass.Type === "password"
                                                        ? SetvisibleChangepass({
                                                            Type: "text",
                                                            iconName: BsEye,
                                                        })
                                                        : SetvisibleChangepass({
                                                            Type: "password",
                                                            iconName: BsEyeSlash,
                                                        });
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="modal-footer ">
                            <button
                                style={{ fontSize: 16, borderRadius: 20 }}
                                onClick={() => ChangePasswordModClose()}
                                type="button"
                                className="btn  btn-outline-secondary"
                            >
                                Close
                            </button>
                            <button
                                onClick={UpdatePassword}
                                style={{ fontSize: 16, borderRadius: 20 }}
                                type="button"
                                className="btn  btn-outline-success"
                            >
                                Update Password
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Sidebar>
    );
};

export default Drawer;
