import React, { useState,useContext } from 'react'


import { UserContext } from "../Pages/Context";

import { NavLink } from 'react-router-dom';
import { FaBookOpen, FaList, FaHeart, FaInfoCircle, FaQuestionCircle, FaExpandAlt, FaSquare, FaLock, FaArrowAltCircleRight } from 'react-icons/fa'
import { MdSettings } from 'react-icons/md'
import {CgProfile } from 'react-icons/cg'

import { useHistory } from "react-router-dom";

import Modal from "@material-ui/core/Modal";


const Navbar = () => {

    
    const { new_Data, setNew_Data } = useContext(UserContext);
    const history = useHistory();

    const [logOutModal, setLogOutModal] = useState(false)
    return (
        <nav className="navbar navbar-expand navbar-light shadow" >
            <div className="container-fluid justify-content-end">
                <ul className="navbar-nav  mb-lg-0">
                   
                    <li className="nav-item">
                        <NavLink className="nav-link" to="#" onClick={()=>setLogOutModal(true)}>Log Out</NavLink>
                    </li>
                </ul>
            </div>

              {/*###############################
            ####### Edit Profile Modal #######
            ############################## */}
            <Modal open={logOutModal}>
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        
                        <div className="modal-body">
                     <h6>Are you sure you want to Log Out</h6>
                     <div className='d-flex justify-content-around col-6 mx-auto mt-3' >

                            <button
                                style={{ fontSize: 16, borderRadius: 20 }}
                                onClick={()=>setLogOutModal(false)}
                                type="button"
                                className="btn  btn-outline-secondary"
                            >
                                Close
                            </button>
                            <button
                                onClick={()=>{
                                    setNew_Data('hello')
                                    setLogOutModal(false)
                                }
                                }
                                style={{ fontSize: 16, borderRadius: 20 }}
                                type="button"
                                className="btn  btn-outline-success"
                                >
                                Log Out
                            </button>
                                </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </nav>
    )
}

export default Navbar
