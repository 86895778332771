import React, { useContext } from "react";
import NavWithSide from "../Components/layout/NavWithSide.jsx"
// './Components/layout/NavWithSide.jsx'
import { UserContext } from "./Context";
import {  Switch, Route } from "react-router-dom";
import BankList from "./Banks/BankList";
import CountryList from "./Countryies/CountryList";
import AdsBBReq from "./Ads/AdsBBReq";
import BuyingReq from './Ads/BuyingReq';
import Login from "./Login.js";
import SellinReq from './Ads/SellinReq';
import AdDetails from "./Ads/AdDetails.js";
import AdsSBReq from "./Ads/AdsSBReq.js";
import UserVerification from "./Users/UserVerification.js";
import UserAdd from "./Users/UserAdd.js";
import UserList from "./Users/UserList.js";
import UsersDetail from "./Users/UsersDetail.js";
import ViewImage from "./Users/ViewImage.js";
import UserWallet from "./Users/UserWallet.js";
import CryptoList from "./Crypto/CryptoList.js";
import CurrancyList from "./Currency/CurrencyList.js";
import PaymentList from "./PaymentMethod.js/PaymentList.js";
import AdminWallet from "./MyWallet/AdminWallet.js";
import Transection from "./Transection/Transection.js";
import BuyLimits from "./Limits/BuyLimits.js";
import SellLimit from "./Limits/SellLimit.js";
import UnAprovedTran from "./Transection/UnAprovedTran.js";
import TransectionDetails from "./Transection/TransectionDetails.js";
import DeclineTransections from "./Transection/DeclineTransections.js";
import DisputeTransection from "./Transection/DisputeTransection.js";
import AppSetting from "./AppSettings/AppSetting.js";
import AproveTransection from "./SwapTransection/AproveTransection.js";
import SwapTransectionDetails from "./SwapTransection/TransectionDetails.js";
import UnAprovedTransection from "./SwapTransection/UnAproveTransection.js";
import DisputeSwapTransection from "./SwapTransection/DisputeSwapTransection";
import SwapDecline from "./SwapTransection/SwapDecline.js";

const Navigation = () => {

    const { new_Data, setNew_Data } = useContext(UserContext);
    const { type } = new_Data;
    
    
    return (


        <>
        <Switch>
            {type !== "admin" ? (
              
           <Route  path="/" component={Login} />
                     
              
              
            ) : (
                   
                <NavWithSide >
                     <Route exact path="/banks/list" component={BankList} />

                            <Route exact path="/countries/list" component={CountryList} />
                            <Route exact path="/ads/buyingbidsrequest" component={AdsBBReq} />
                            <Route exact path="/ads/buyingrequest" component={BuyingReq} />
                            <Route exact path="/ads/sellingrequest" component={SellinReq} />
                            <Route exact path="/ads/addetails" component={AdDetails} />
                            <Route exact path="/ads/sellingbidsrequest" component={AdsSBReq} />
                            <Route exact path="/users/verification" component={UserVerification} />
                            <Route exact path="/users/add" component={UserAdd} />
                            <Route exact path="/users/list" component={UserList} />
                            <Route exact path="/users/detail" component={UsersDetail} />
                            <Route exact path="/users/image" component={ViewImage} />
                            <Route exact path="/users/wallet" component={UserWallet} />

                            <Route exact path="/crypto/list" component={CryptoList} />

                            <Route exact path="/currancy/list" component={CurrancyList} />
                            <Route exact path="/paymentmethod/list" component={PaymentList} />
                            <Route exact path="/amountlimit/buylimit" component={BuyLimits} />
                            <Route exact path="/amountlimit/selllimit" component={SellLimit} />
                            <Route exact path="/mywallet" component={AdminWallet} />
                            <Route exact path="/transection/aproved" component={Transection} />
                            <Route exact path="/transection/unaproved" component={UnAprovedTran} />
                            <Route exact path="/transection/details" component={TransectionDetails} />
                            <Route exact path="/transection/dispute" component={DisputeTransection} />
                            <Route exact path="/transection/decline" component={DeclineTransections} />
                            <Route exact path="/swaptransection/aproved" component={AproveTransection} />
                            <Route exact path="/swaptransection/details" component={SwapTransectionDetails} />
                            <Route exact path="/swaptransection/dispute" component={DisputeSwapTransection} />
                            <Route exact path="/swaptransection/unaprovedtransection" component={UnAprovedTransection} />
                            <Route exact path="/swaptransection/decline" component={SwapDecline} />
                            <Route exact path="/appsetting" component={AppSetting} />

                </NavWithSide>
                   
                   )}
                   </Switch>
        </>


    )
}

export default Navigation
