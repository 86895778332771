import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import API from "../../Apis/Api";
function AddLimit(props) {
  const { open, close, BGetData, priceType } = props;
  console.log(priceType, 'priceType')
  const [cryptoApi, setCryptoApi] = useState([]);
  const [currencyApi, setCurrencyApi] = useState([]);
  const [cryptoLimit, setCryptoLimit] = useState('');
  const [currencyLimit, setCurrencyLimit] = useState('');

  const [sellectedCrypto, setSellectedCrypto] = useState(
    JSON.stringify({
      id: 1,
      name: ''

    })
  );
  const [sellectedCurrency, setSellectedCurrency] = useState(
    JSON.stringify({
      id: 1,
      name: ''

    })
  );
  const selectedCrypto = JSON.parse(sellectedCrypto);
  const selectedCurrency = JSON.parse(sellectedCurrency);
  // console.log(selectedCurrency,'selectedCrypto')

  const AddLimit = async () => {

    //  console.log(  priceType,
    //   selectedCrypto.id,
    //   selectedCurrency.id,
    //   cryptoLimit,
    //   currencyLimit,'selecteselectedCryptodCrypto')
    try {
      if (cryptoLimit.length >= 1 && currencyLimit.length >=1) {

        const res = await API().getAddLimit(
          priceType,
          selectedCrypto.id,
          selectedCurrency.id,
          cryptoLimit,
          currencyLimit
        )

        if (res.status == true) {
          close()
          BGetData()
        }
      }
    } catch (err) {

      console.log(err, "errbankedit")
    }
  }

  const GetData = async () => {
    try {
      const res = await API().getcrypto();
      const resCurrency = await API().getcurrency();
        // console.log(resCurrency,'eeee')
      setSellectedCrypto(JSON.stringify(res[0]))
      setSellectedCurrency(JSON.stringify(resCurrency[0]))
      setCurrencyApi(resCurrency)
      setCryptoApi(res);
    } catch (err) {
      console.log(err, "getcryptocureency");
    }
  };



  const handleChange = (event) => {
    setSellectedCrypto(event.target.value);
  };
  const handleChangeCurrency = (event) => {
    setSellectedCurrency(event.target.value);
  };
  useEffect(() => {
    GetData();
  }, []);
  return (
    <div>
      <Modal open={open}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Limit</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                <div className="d-flex p-0 col-11 justify-content-between">
                  <h4 >Select Crypto:</h4>
                  <select
                    style={{ width: "40%" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => handleChange(e)}
                  >
                    <option selected>{selectedCrypto.symbol}</option>
                    {cryptoApi.map((item) => {
                      return (
                        <>
                          {item.id != selectedCrypto.id ? (
                            <>
                              <option
                                key={item.id}
                                value={JSON.stringify(item)}
                              >
                                {item.symbol}
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </select>
                </div>

                <div className="d-flex p-0 mt-2 col-11 justify-content-between">
                  <h4 >Select Currency:</h4>
                  <select
                    style={{ width: "40%" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => handleChangeCurrency(e)}
                  >
                    <option selected>{selectedCurrency.symbol}</option>
                    {currencyApi.map((item) => {
                      return (
                        <>
                          {item.id != selectedCurrency.id ? (
                            <>
                              <option
                                key={item.id}
                                value={JSON.stringify(item)}
                              >
                                {item.symbol}
                              </option>
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </select>
                </div>

                {cryptoLimit.length >= 1 ? (
                  <>  <h5 className="mt-3 col-11 p-0">Crypto Limit: </h5>

                  </>) : (
                  <>
                    <h5 className="mt-3 col-11 text-danger p-0">Crypto Limit: </h5>


                  </>
                )}

                <div
                  className=" border col-11  rounded  shadow-sm mx-auto "
                  style={{
                    // width: "90%",
                    height: "45px",

                  }}
                >

                  <input
                    value={cryptoLimit}
                    className="form-control mt-1 border-0 shadow-none  "


                    placeholder="Crypto Limit"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setCryptoLimit(e.target.value)
                      }
                    }}

                  />
                </div>

                {currencyLimit.length >= 1 ? (
                  <>  <h5 className="mt-3 col-11 p-0">Currency Limit: </h5>

                  </>) : (
                  <>
                    <h5 className="mt-3 col-11 text-danger p-0">Currency Limit: </h5>


                  </>
                )}

                <div
                  className=" border col-11  rounded  shadow-sm mx-auto "
                  style={{
                    // width: "90%",
                    height: "45px",

                  }}
                >

                  <input
                    value={currencyLimit}
                    className="form-control mt-1 border-0 shadow-none  "


                    placeholder="Currency Limit"
                    onChange={(e) => {
                      if (!isNaN(e.target.value)) {
                        setCurrencyLimit(e.target.value)
                      }
                    }}

                  />
                </div>

              </div>
            </div>

            <div className="modal-footer">

              <button
                style={{ width: "90%", fontSize: 25 }}
                type="button"
                className="btn btn-success mx-auto "
                onClick={() => AddLimit()}
              >
                Add Limit
              </button>
            </div>
          </div>
        </div >
      </Modal >
    </div >
  );
}

export default AddLimit;
