import React,{useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";
import API from '../../Apis/Api';
import {  FiCameraOff } from "react-icons/fi";
import { AiOutlineClose } from "react-icons/ai";
import ScrollToBottom from 'react-scroll-to-bottom';
import Modal from "@material-ui/core/Modal";
import { LoginAPI } from '../../Apis/Api';


import io from 'socket.io-client';


const domain = 'https://f557-162-241-121-1.ngrok.io'
var socket = io(domain);
function TransectionDetails(props) {
  const userData=  JSON.parse(localStorage.getItem("userData"))

  const { id } = userData.data;
 
    const [isLoading, setIsLoading] = useState(false);

    const history = useHistory();

    const [chatShow, setChatShow] = useState(0)
    const [resMessage, setResMessage] = useState([]);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fullSizeImage, setFullSizeImage] = useState("");
    const [chatButton, setChatButton] = useState('Chat')

    const [message, setMessage] = useState('');
    const [data1, setData1] = useState(0);
    var getMessages = []

    const {

        account_name,
        ad_type,
        amount_of_crypto,
        bank,
        bank_account_number,
        created_at,
        crypto_currency,
        
        is_deleted,
        is_disabled,
        maximum_crypto_amount,
        // minimum_crypto_amount,
        minimum_crypto_price,
        payment_method,
        price,
        price_type,
        symbol,
        updated_at,
        user_id,
        ad_detail,
        approve_status,
        decline_status,
        from_user,
        tansection_id,
        quantity,
        to_user, bid_amount,
        currency_symbol,
        transection_image,
        dispute_transection,
        dispute_by,
        to_user_email,
        from_user_email
     } = props.location.state
    console.log(props.location.state, 'props.location.state')


    const aprovedTransection = async () => {
        alert('aprove')
        // try {
        //     const res = await API().postAcceptTransection(
        //         tansection_id
        //     )

        //     console.log(tansection_id, 'tansection_id')
        //     console.log(res, 'res tansection_id')
        //     if (res.status) {
        //         history.replace(
        //             "/transection/aproved",

        //         )

        //     }
        // } catch (err) { console.log(err, 'errAcceptTransection') }
    }
    const DeclineTransection = async () => {
        // console.log(tansection_id,'tansection_id............................................!')
        alert('Decline')
        // try {
        //     const res = await API().getDeclineTransection(
        //         tansection_id
        //     )

        //     console.log(tansection_id, 'tansection_id')
        //     console.log(res, 'res tansection_id')
        //     if (res.status) {
        //         history.replace(
        //             "/transection/decline",

        //         )

        //     }
        // } catch (err) { console.log(err, 'errAcceptTransection') }
    }
    const ShowImage = (item) => {
        setShowImageModal(true)
        setFullSizeImage(item)
    }

    const chatOpen = () => {
        if (chatShow == 0) {

            setChatShow(1)
            setChatButton('Close')
        } else {
            setChatShow(0)
            setChatButton('Chat')
        }
    }
//     const GetDat= async()=>{

//         const resMessage = await LoginAPI().postMessage(tansection_id)
//         setResMessage(resMessage.data)
// console.log(socket,'socket>>>>>>>>>>>>>>>>>>>>>>>>>>>>...')
//         socket.emit("user_join", { "t_id": tansection_id, "u_id": id })

//         socket.on("user_joined", (data) => {

//         })


//         socket.on("message", (data) => {

//             getMessages = resMessage.data
//             let senderDada = JSON.parse(data.sender)
//             let dd = {
//                 Message: data.message,
//                 first_name: senderDada.first_name,
//                 file: senderDada.file,
//                 last_name: senderDada.last_name,
//                 user_id: senderDada.id,
//                 file: data.url
//             }


//             console.log(JSON.parse(data.sender), 'data>>>>>>>>>>>>>>>>>>>>>buying')

//             if (id != senderDada.id) {
//                 getMessages.push(dd)

//                 // audio.play()


//                 setResMessage(getMessages)
//             } else {
//                 // setResMessage(getMessages)

//             }
//             setData1(1)
//             setData1(0)
//         })

//         setIsLoading(false)


//     }
//    useEffect(() => {
//     GetDat()
//    }, [])
   
    if (isLoading) {
        return (
          <div className="d-flex justify-content-center container  align-items-center">
            <div className="spinner-border text-secondary" role="status"></div>
          </div>
        );
      } else {
    return (
        <div className="container">
<div className='d-flex justify-content-center'>
            <div className="  col-xl-6  border shadow rounded ">
                <div className=" col-11 m-2  d-flex flex-wrap flex-column align-items-center">
                    {dispute_transection ==1?

                    <h5 style={{color:'red'}}>This transaction has been disputed by {dispute_by}</h5>
                :<></>}
                    <div className=" border col-3  shadow-sm" style={{ height: "350px", width: "400px" }}>
                        <img
                            src={transection_image}
                            className="img-thumbnail  "
                            alt="Profile"
                            style={{ height: "350px", width: "400px" }}
                        />
                    </div>



                    <div className="col-7 mt-2  justify-content-center  d-flex">
                        <h5 style={{ marginTop: 10 }}>Seller Name:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {from_user}
                        </h5>
                    </div>
                    <div className="col-7 mt-2  justify-content-center  d-flex">
                        <h5 style={{ marginTop: 10 }}>Seller Email:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {from_user_email}
                        </h5>
                    </div>
                    <div className="col-7 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Buyer Name:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {to_user}
                        </h5>
                    </div>
                    <div className="col-8 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Buyer Email:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {to_user_email}
                        </h5>
                    </div>

                    <div className="col-8 justify-content-center d-flex">
                        <h5 style={{ marginTop: 10 }}>Selling {symbol}:</h5>
                        {/* <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {ad_detail.minimum_crypto_amount}{ad_detail.symbol} to
                            {' '}{ad_detail.maximum_crypto_amount}{ad_detail.symbol}
                        </h5> */}
                    </div>
                    {/* <div className="col-7 justify-content-center d-flex">

                        <h5 style={{ marginTop: 10 }}>Buying:</h5>

                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {quantity}{ad_detail.symbol}
                        </h5>


                    </div> */}


                    {/* <div className="col-7 justify-content-center  d-flex">
                        <h5 style={{ marginTop: 10 }}>Amount:</h5>
                        <h5 style={{ color: "gray", marginTop: 10, marginLeft: "10px" }}>
                            {bid_amount}{currency_symbol}
                        </h5>
                    </div> */}

                    {approve_status  || decline_status ?
 <></>:
 
                    <div className=" col-10  d-flex  justify-content-between ">
 {tansection_id>0?

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={aprovedTransection} >Approve</button>
:<></>}
                        {/* <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-danger  " onClick={DeclineTransection} > Roll Back Transaction</button>

                        <button style={{ borderRadius: "20px", width: 120, height: 38 }} className="btn  btn-outline-info " onClick={chatOpen}  >{chatButton}</button> */}

                    </div>
              
                   }
                </div>
            </div>
   {/*######################
            ######### CHAT ########## 
            ######################*/}
{/* <div className='border'> */}
{chatShow == 1 ?
                    <div className='    col-lg-4  border rounded' style={{ backgroundColor: 'white'}}>
                        {resMessage.length == 0 ?
                            <div style={{ height: "100%", }} className='chatDivAdd d-flex justify-content-center border align-items-center '>
                                <h5 className='' style={{ color: 'rgb(221, 221, 221)' }}>You have no conversation</h5>
                            </div>
                            :

                            <ScrollToBottom className="overflow-auto  chatDiv">
                                {resMessage.map((item) => {
                                    // console.log(item, '.................................................>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                                    return (
                                        <>
                                            {item.user_id == id ?
                                             <></>
                                                :
                                                <div style={{ padding: 5 }} className="border col-lg-10 col-sm-10 rounded d-flex mt-1 justify-content-between">

                                                    <div className=" border d-flex align-items-center  shadow-sm rounded-circle justify-content-center  " style={{ height: "40px", width: "40px" }}>
                                                        {item.image != null ?
                                                            <img
                                                                src={`https://foloswap.com${item.image}`}
                                                                class="img-thumbnail rounded-circle "
                                                                alt="Profile"
                                                                style={{ height: "40px", width: "40px" }}
                                                            />
                                                            : <div className=" border col-3 d-flex justify-content-center align-items-center shadow-sm" style={{ borderRadius: '100px', height: "40px", width: "40px" }} >

                                                                <FiCameraOff color="gray" size={20} />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div style={{ width: 280 }} className="">
                                                        <h6 style={{ marginTop: 1, color: 'black' }} className="">{item.first_name}</h6>
                                                        <p style={{ marginTop: -5, lineHeight: 1, color: 'gray' }} className="">{item.Message}</p>
                                                        {item.file != null ?
                                                            <img
                                                                onClick={() => ShowImage(`${domain}/static/uploads/${item.file}`)}
                                                                src={`${domain}/static/uploads/${item.file}`}
                                                                style={{ height: 280, width: 250 }}
                                                                className=""
                                                                alt="Profile"
                                                            />
                                                            : <></>}
                                                    </div>
                                                </div>
                                            }
                                        </>

                                    )
                                })}

                            </ScrollToBottom>}

                      



                    </div>
                    : <></>
                }
            {/* </div> */}
            </div>

                {/* ###########################
      ########## Show Image ##########
      ##########################* */}
            <Modal open={showImageModal}>
                <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header  justify-content-end">

                            <AiOutlineClose
                                onClick={() => setShowImageModal(false)}
                                size={20}
                                style={{ marginRight: 15 }}
                            />
                        </div>
                        <div className="modal-body">
                            <img

                                src={fullSizeImage}
                                style={{ height: 420, width: "100%" }}
                                className=""
                                alt="Profile"
                            />
                        </div>
                    </div>
                </div>
            </Modal>
        </div >
    )
}
}

export default TransectionDetails
