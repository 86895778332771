import React, { useEffect, useState } from 'react'
import API from '../../Apis/Api'

function AppSetting() {

    const [isLoading, setIsLoading] = useState(true);

    const [apiPersentag, setApiPersentag] = useState('')
    const [apiTransferCoinPercent, setApiTransferCoinPercent] = useState('')

    const [inputP2P, setInputP2P] = useState('')
    const [inputDirectTransaction, setInputDirectTransaction] = useState('')


    const [P2pLoader, setP2pLoader] = useState(false)
    const [P2pMessage, setP2pMessage] = useState('')

    const [directLoader, setDirectLoader] = useState(false)
    const [directMessage, setDirectMessage] = useState('')


    const [validation, setValidation] = useState({
        P2P: true,
        DirectTransaction: true

    });

    const P2PValid = function (ad) {
        return function (ad) {
            if (ad.length > 0) {
                return true;
            } else {
                return false;
            }
        };
    };
    const DirectTransactionValid = function (ad) {
        return function (ad) {
            if (ad.length > 0) {
                return true;
            } else {
                return false;
            }
        };
    };

    const GetData = async () => {
        try {
            const res = await API().getPercentage()

            setApiPersentag(res.percentage)
            setApiTransferCoinPercent(res.transfer_coin_percent)
            // console.log(res,'resPercentage')
            setIsLoading(false)
        } catch (error) {
            console.log(error, 'errorAppSetting')
        }
    }
    useEffect(() => {
        GetData()
    }, [])

    const P2PTransaction = async () => {

        var P2pValid = P2PValid("ad");
        var dataP2P = P2pValid(inputP2P);
        setValidation({ 
            P2P: dataP2P,
            DirectTransaction: true })
            setP2pMessage('')
        if (dataP2P) {
            setP2pLoader(true)
            try {
                const res = await API().postEditPercentage(
                    inputP2P,
                    apiTransferCoinPercent
                )
                console.log(res, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                if (res.status) {
                    setInputP2P('')
                setP2pMessage(res.msg)
                    GetData()
                }
                setP2pLoader(false)
            } catch (error) {

                console.log(error, 'errP2PTransaction>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            }
        }
        // 
    }
    const DirectTransaction = async () => {

        var DirectTranValid = DirectTransactionValid("ad");
        var dataDirectTran = DirectTranValid(inputDirectTransaction);
        setValidation({
            P2P: true,
            DirectTransaction: dataDirectTran
        })
        setDirectMessage('')
        if (dataDirectTran) {
            setDirectLoader(true)
            try {
                const res = await API().postEditPercentage(
                    apiPersentag,
                    inputDirectTransaction
                )
                console.log(res, '>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
                if (res.status) {
                    setInputDirectTransaction('')
                    setDirectMessage(res.msg)
                    GetData()
                }
                setDirectLoader(false)
            } catch (error) {

                console.log(error, 'errDirectTranValid>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>')
            }
        }
        // 
    }

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center container  align-items-center">
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    } else {
        return (
            <div className=" container ">
                <div className=" shadow border p-4 mt-5 rounded col-lg-6 mx-auto ">

                    <h4 className='text-center'>COMISSION SETUP</h4>

                    <div className="mt-4">

                        <div className='d-flex ' >
                            <h5>

                                P2P Transaction Fee:
                            </h5>
                            <div className='mx-3 px-4 py-1' style={{ backgroundColor: '#4F2549', color: 'white', marginTop: -5, height: 30 }}>
                                {apiPersentag}%
                            </div>
                        </div>
                        <div className="d-flex">
                            {validation.P2P ?
                                <div
                                    className=" d-flex justify-content-between shadow-sm  "
                                    style={{
                                        width: "60%",
                                        height: "40px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        border: "1px solid gray",
                                        borderRadius: 5,
                                    }}
                                >
                                    <input
                                        value={inputP2P}
                                        className="form-control border-0 shadow-none  "
                                        style={{
                                            width: "85%",
                                            paddingLeft: 20,

                                        }}
                                        placeholder="Set New P2P Transaction Fee"
                                        onChange={(e) => setInputP2P(e.target.value)}
                                    />
                                </div>
                                :
                                <div
                                    className=" d-flex justify-content-between shadow-sm  "
                                    style={{
                                        width: "60%",
                                        height: "40px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        border: "1px solid red",
                                        borderRadius: 5,
                                    }}
                                >
                                    <input

                                        value={inputP2P}
                                        className="form-control border-0 shadow-none  "
                                        style={{
                                            width: "85%",
                                            paddingLeft: 20,

                                        }}
                                        placeholder="Enter New P2P Transaction Fee"
                                        onChange={(e) => setInputP2P(e.target.value)}
                                    />
                                </div>
                            }
                            <button onClick={P2PTransaction} className="btn px-4 ms-4 btn-outline-info "  > {P2pLoader ?
                                <div style={{ height: 20, width: 20 }} className="spinner-border " role="status"></div> : 'Save'}</button>
                        </div>
                        <div className='col-7 text-center text-success'>{P2pMessage}</div>
                    </div>
                    <div className="mt-4">
                        <div className="d-flex">
                            <h5>

                                Direct transfer Fee:
                            </h5>
                            <div className='mx-3 px-4 py-1' style={{ backgroundColor: '#4F2549', color: 'white', marginTop: -5, height: 30 }}>
                                {apiTransferCoinPercent}%
                            </div>
                        </div>
                        <div className="d-flex">
                            {validation.DirectTransaction ?
                                <div
                                    className=" d-flex justify-content-between shadow-sm  "
                                    style={{
                                        width: "60%",
                                        height: "40px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        border: "1px solid gray",
                                        borderRadius: 5,
                                    }}
                                >
                                    <input
                                        value={inputDirectTransaction}
                                        className="form-control border-0 shadow-none  "
                                        style={{
                                            width: "85%",
                                            paddingLeft: 20,

                                        }}
                                        placeholder="Set New Direct transfer Fee"
                                        onChange={(e) => setInputDirectTransaction(e.target.value)}
                                    />
                                </div>
                                :
                                <div
                                    className=" d-flex justify-content-between shadow-sm  "
                                    style={{
                                        width: "60%",
                                        height: "40px",
                                        backgroundColor: "rgb(255, 255, 255)",
                                        paddingTop: 10,
                                        paddingBottom: 10,
                                        border: "1px solid red",
                                        borderRadius: 5,
                                    }}
                                >
                                    <input
                                        value={inputDirectTransaction}
                                        className="form-control border-0 shadow-none  "
                                        style={{
                                            width: "85%",
                                            paddingLeft: 20,

                                        }}
                                        placeholder="Enter New Direct transfer Fee"
                                        onChange={(e) => setInputDirectTransaction(e.target.value)}
                                    />
                                </div>
                            }
                            
                            <button onClick={DirectTransaction} className="btn px-4 ms-4 btn-outline-info "  > {directLoader ?
                                <div style={{ height: 20, width: 20 }} className="spinner-border " role="status"></div> : 'Save'}</button>
                        </div>
                                <div className='col-7 text-center text-success'>{directMessage}</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default AppSetting