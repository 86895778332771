import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";
import { IconButton, Tooltip } from "@material-ui/core";

import Modal from "@material-ui/core/Modal";
import AddCurrancy from "./AddCurancy";
import EditCurrancy from "./EditCurrancy";
// import AddMod from "./AddCountry";
// import EditCountryMod from "./EditCountryMod";


function CurrancyList() {
   
    const [isLoading, setIsLoading] = useState(true);
    const [dataValues, setDataValues] = useState([]);
    const [currencyId, setCurrencyId] = useState("");
    const [editMod, setEditMod] = useState(false);
    const [addMod, setAddMod] = useState(false);
    const [deleteMod, setDeleteMod] = useState(false);
    const [editData, setEditData] = useState("");
    const [openMod, setOpenMod] = useState(0);

    const EditModalOpen = (data) => {
        setEditMod(true);
        setOpenMod(1)
        setEditData(data)
        GetData()

    };
    const AddModalOpen = () => {
        setAddMod(true);
    };
    const DeleteModalOpen = (item) => {
        setCurrencyId(item)
        setDeleteMod(true);
    };
    const EditModalClose = () => {
        setEditMod(false);


    };
    const AddModalClose = () => {
        setAddMod(false);
    };
    const DeleteModalClose = () => {

        setDeleteMod(false);
    };
    const GetData = async () => {
        try {
            setIsLoading(true)
            const res = await API().getCurrancyList();
            let newData = [];
            res.currency.map((item, index) => {
                // console.log(item.id,"currancy")
                newData.push({
                    s_no: index + 1,
                    isdisabled: (!item.is_disabled) ? "Enable" : "Disabled",
                    edit: (
                        <Tooltip title="Edit">
                            <IconButton onClick={() => { EditModalOpen(item) }}>
                                <BiEditAlt className="text-warning" />
                            </IconButton>
                        </Tooltip>
                    ),
                    delete: (
                        <Tooltip title="delete">
                            <IconButton onClick={() => { DeleteModalOpen(item.id) }}>
                                <RiDeleteBin6Line className="text-danger" />
                            </IconButton>
                        </Tooltip>
                    ),
                    disable: ((!item.is_disabled ?

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={()=>DisableCurrency(item.id)} >Disable</button>
                        :
                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-danger " onClick={()=>DisableCurrency(item.id)}>Enable</button>
                      )),
                    ...item,
                });
            });
            setIsLoading(false);

            setDataValues(newData);
        } catch (err) {
            console.log(err,'currancylist');
        }
    };
    const DeleteCurrency = async () => {

        try {
            const res = await API().getDeleteCurrency(
                currencyId
            )
            if (res.status == true) {
                GetData()
                DeleteModalClose()
            }

        } catch (err) {
            console.log(err, "deleteBank")
        }
    }
    const DisableCurrency=async(id)=>{
       console.log(id,'iiiidddd')
       
        try {
          const res = await API().getDisableCurrency(
            id
          )
          if (res.status == true) {
            GetData()
        
          }
    
        } catch (err) {
          console.log(err, "getDisableBank")
        }
    
      }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "s_no",
            label: "S.no",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "name",
            label: "Currancy Name",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "symbol",
            label: "Symbol",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "isdisabled",
            label: "Status",
            options: {
              filter: true,
              sort: true,
            },
          },
        {
            name: "edit",
            label: "Edit",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "delete",
            label: "Delete",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "disable",
            label: "Disable",
            options: {
              filter: true,
              sort: true,
            },
          },
    ];

    const options = {
        filterType: "checkbox",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        setTableProps: () => {
            return {
                // material ui v4 only
                size: "small",
            };
        },
        customToolbar: () => {
            return (
                <Tooltip title="Add currancy">
                    <IconButton onClick={AddModalOpen} >
                        <FaPlus className="text-success" />
                    </IconButton>
                </Tooltip>
            )
        }
    };
    useEffect(() => {
        GetData();
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center container  align-items-center">
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    } else {
        return (
            <div className=" container ">
                <MUIDataTable
                    title={"Currancy List"}
                    data={dataValues}
                    columns={columns}
                    options={options}
                />
                {/*##########################
           ###### Edit Modal#########
           ########################## */}
                {openMod === 1 ? (

          <EditCurrancy data={editData} BGetData={GetData} open={editMod} close={EditModalClose} />
        ) : (<></>)}

                {/*##########################
           ###### Add Modal#########
           ########################## */}
                <AddCurrancy open={addMod} BGetData={GetData} close={AddModalClose} />
                {/*##########################
           ###### Delete Modal#########
           ########################## */}

                <Modal open={deleteMod}>
                    <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                        <div className="modal-content">

                            <div className="modal-body">
                                <div className=" col-10 mt-2 mx-auto text-center">

                                    <h6 style={{ fontSize: '18px' }}>Are you sure you want to Delete this Currancy</h6>
                                    <div className="d-flex col-8 mt-4 mx-auto justify-content-between ">
                                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-dark " onClick={DeleteModalClose}>close</button>
                                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={DeleteCurrency}>Delete</button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div >
                </Modal >

            </div>
        );
    }
}

export default CurrancyList;
