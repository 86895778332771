import React from 'react';

const Sidebar = (props) => {
    const LTR = (props.LTR) ? ({ right: 0 }) : ({ right: "none" });
    return (
        <div className="sidebar" style={{ background: (props.image) ? ("") : props.bgColor, right: LTR.right }}>
            <img className="sildebar_img" src={props.image} alt="navinage" />
            <div className="overlayer" style={{ background: (props.image) ? (props.bgColor + '8f') : "none" }}></div>
            {
                (Array.isArray(props.children)) ? (
                    props.children.map((val) => {
                        return val;
                    })
                ) : (
                    props.children
                )
            }
        </div>
    )
}

export default Sidebar
