import React, { useState, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import { AiOutlineClose } from "react-icons/ai";
import { CountryDropdown, } from 'react-country-region-selector';
import { CountriesData } from "../../Components/Country";
import API from "../../Apis/Api";
function AddMod(props) {
  const { open, close, BGetData } = props;
  const conuntryData = CountriesData

  const [countryApi, setCountryApi] = useState(conuntryData);
  const [bankName, setBankName] = useState('');


  const [sellectedCountry, setSellectedCountry] = useState(
    JSON.stringify({
      // id: 1,
      name: 'Afghanistan',
      phoneCode: "+93"

    })
  );
  const selectedCountry = JSON.parse(sellectedCountry);

  
  const AddCountry = async () => {
    try {

      
      const res = await API().postAddCountry(

        selectedCountry.name,
          selectedCountry.phoneCode
      )
 

      if (res.status == true) {
        close()
        BGetData()
      }

    } catch (err) {

      console.log(err, "errbankedit")
    }
  }





  const handleChange = (event) => {
    // console.log(event.target.value,'event.target.value')
    setSellectedCountry(event.target.value);
  };

  return (
    <div>
      <Modal open={open}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h2>Add Country</h2>
              <AiOutlineClose
                onClick={close}
                size={20}
                style={{ marginRight: 15 }}
              />
            </div>
            <div className="modal-body">
              <div className=" row justify-content-center">
                <div className=" p-0 col-11 ">
                  <h5 >Select Country</h5>
                  <select
                    style={{ width: "100%" }}
                    className="form-select"
                    aria-label="Default select example"
                    onChange={(e) => handleChange(e)}
                  >

                    {countryApi.map((item) => {
                      return (


                        <option
                          key={item.id}
                          value={JSON.stringify(item)}
                        >
                          {item.name}
                        </option>



                      );
                    })}
                  </select>
                </div>



                <h5 className="mt-3 col-11 p-0">Selected Country: </h5>



                <div
                  className=" d-flex  col-11 justify-content-between     mx-auto "
                  style={{
                    // width: "90%",
                    // height: "50px",

                  }}
                >
                  <h6 style={{ height: "50px", paddingTop: '13px', fontSize: '18px', color: 'gray', borderRadius: '10px' }} className="border  my-auto shadow-sm col-2 text-center ">
                    {selectedCountry.phoneCode}

                  </h6>
                  <h6 style={{ height: "50px", paddingTop: '13px', paddingLeft: "8px", paddingRight: '8px', fontSize: '18px', color: 'gray', borderRadius: '10px' }} className="border my-auto shadow-sm col-9 ">
                    {selectedCountry.name}

                  </h6>

                </div>
              </div>
            </div>

            <div className="modal-footer">

              <button
                style={{ width: "90%", fontSize: 25 }}
                type="button"
                className="btn btn-success mx-auto "
                onClick={() => AddCountry()}
              >
                Country Add
              </button>
            </div>
          </div>
        </div >
      </Modal >
    </div >
  );
}

export default AddMod;
