import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import API from "../../Apis/Api";

import { useHistory } from "react-router-dom";


function Buying() {
    const [isLoading, setIsLoading] = useState(true);
    const [dataValues, setDataValues] = useState([]);



    const history = useHistory();


    const GetData = async () => {
        try {
            setIsLoading(true)
            const res = await API().getAllAds();
    
            let newData = [];
            res.buying_ads.map((item, index) => {
           
                newData.push({
                    s_no: index + 1,
                    Price: item.price +" "+item.currency_symbol+"/"+  item.symbol,
                    maximum: item.maximum_crypto_amount +" "+ item.symbol,
                    isdisabled: (!item.ad_disable) ? "Enable" : "Disabled",
                    viewdetail: (

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-outline-info  " onClick={() =>AdDetails(item) } > Detail</button>
                    ),
                    disable: ((!item.ad_disable ?

                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-success " onClick={() => DisableAds(item.ad_id)}  >Disable</button>
                        :
                        <button style={{ borderRadius: "20px" }} className="btn px-4 btn-danger "  onClick={() => DisableAds(item.ad_id)} >Enable</button>
                    )),
                    ...item,
                });

            });
            setIsLoading(false);

            setDataValues(newData);
        } catch (err) {
            console.log(err);
        }
    };
    const AdDetails = (item) => {
        
        history.push(
            "/ads/addetails",
              item 
        )
    }

    const DisableAds = async (id) => {


        try {
            const res = await API().getDisableAds(
                id
            )
            if (res.status == true) {
                GetData()

            }

        } catch (err) {
            console.log(err, "getDisableBank")
        }

    }

    const columns = [
        {
            name: "id",
            label: "ID",
            options: {
                filter: false,
                sort: false,
                display: false,
            },
        },
        {
            name: "s_no",
            label: "S.no",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "Price",
            label: "Price",
            options: {
                filter: false,
                sort: true,
            },
        },
        {
            name: "maximum",
            label: "Maximum Amount",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "bank_name",
            label: "Bank",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "isdisabled",
            label: "Status",
            options: {
                filter: true,
                sort: true,
            },
        },
        {
            name: "viewdetail",
            label: "Detail",
            options: {
                filter: false,
                sort: true,
            },
        },

        {
            name: "disable",
            label: "Disable",
            options: {
                filter: true,
                sort: true,
            },
        },
    ];

    const options = {
        filterType: "checkbox",
        download: false,
        print: false,
        filter: false,
        viewColumns: false,
        selectableRows: "single",
        selectableRowsHideCheckboxes: true,
        setTableProps: () => {
            return {
                // material ui v4 only
                size: "small",
            };
        },

    };
    useEffect(() => {
        GetData();
    }, []);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center container  align-items-center">
                <div className="spinner-border text-secondary" role="status"></div>
            </div>
        );
    } else {
        return (
            <div className=" container ">
                <MUIDataTable
                    title={"Buying Ads"}
                    data={dataValues}
                    columns={columns}
                    options={options}
                />




            </div>
        );
    }
}

export default Buying;
