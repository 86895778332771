import React from 'react'

function UserWallet() {
    return (
        <div>
            <h1>User Wallet</h1>
        </div>
    )
}

export default UserWallet
